import { httpClient } from './helpers';
import { AccountIntegration, CollectionResult } from './models';

const client = httpClient('v1');
export async function getAccountIntegrations(
	accountId: string,
	src?: string
): Promise<AccountIntegration[]> {
	let url = `/account-integrations/${accountId}`;
	if (src) {
		url += `?src=${src}`;
	}
	const { data: integrations } = await client.get<CollectionResult<AccountIntegration>>(
		url
	);
	return integrations.results;
}

export async function upsertAccountIntegration({
	accountId,
	integrationId,
	connectionRID,
}: {
	accountId: string;
	integrationId: string;
	connectionRID: string;
}) {
	const url = `/account-integrations/${integrationId}/${accountId}/${encodeURIComponent(
		connectionRID
	)}`;
	await client.put(url);
}
