import { httpClient } from './helpers';
import { ContactRecord, GetContactRecordsResult } from './models';

const client = httpClient('v1');
export async function getIntegrationRecords(opts: {
	connectionRID: string;
	searchTerm?: string;
}): Promise<ContactRecord[]> {
	const { connectionRID, searchTerm } = opts;
	const query = new URLSearchParams();
	if (searchTerm) {
		query.set('searchTerm', searchTerm);
	}

	const { data } = await client.get<GetContactRecordsResult>(
		`/connection/${encodeURIComponent(connectionRID)}/records?${query}`
	);
	return data.results;
}
