import React from 'react';
import { Prospect, WorkflowStatus } from '@citrite/sf-api';
import {
	Button,
	Checkbox,
	EmptyPeopleIcon,
	EmptyState,
	InProgressBadge,
	LoadingOverlaySecondary,
	Table,
	TableContainer,
	TBody,
	TD,
	TH,
	THead,
	Tooltip,
	TR,
	withModals,
	WithModalsProps,
} from '@sharefiledev/flow-web';
import { Account } from '@sharefiledev/sharefile-appshell';
import { ContactRecord, globalIdForRecord } from '../../api/models';
import { WorkflowTrigger } from '../../api/workflow-triggers';
import { usePiralContext } from '../../context';
import { logger } from '../../helpers/logger';
import { pendoSendTrackEvent } from '../../helpers/pendo';
import { useUserState } from '../../state/user-state';
import { t } from '../../translate';
import { Avatar } from '../Avatar';
import {
	EmptyStateLoaderPlaceHolder,
	StyledActions,
	StyledButtonContainer,
	StyledContextBarContainer,
	StyledEllipsisElement,
	StyledSearchContent,
	StyledSearchInput,
	StyledTableContainer,
	StyledTableDataElement,
} from './ContactRecordsTable.styled';
import { MockProspectWorkflowExtension } from './MockProspectWorkflowExtension';

export interface Props extends WithModalsProps {
	integrationId: string;
	contacts: ContactRecord[];
	isLoading?: boolean;
	header?: string;
	setSelectedContacts?: (contacts: ContactRecord[]) => void;
	selectedContacts?: ContactRecord[];
	searchTerm?: string;
	setSearchTerm?: (searchTerm: string) => void;

	triggerIdsForSelectedContacts?: string[];
	workflowStatus?: (contact: ContactRecord) => WorkflowStatus;
	onWorkflowsTriggered?: (contacts: ContactRecord[], triggers: WorkflowTrigger[]) => void;
}

function equalIds(a: ContactRecord, b: ContactRecord): boolean {
	return a.accountId === b.accountId && a.externalRecordId === b.externalRecordId;
}

function toggleSelected(
	contacts: ContactRecord[],
	contact: ContactRecord,
	currentlySelected: boolean
): ContactRecord[] {
	if (currentlySelected) {
		return contacts.filter(c => !equalIds(c, contact));
	}

	if (contacts.includes(contact)) {
		return contacts;
	}
	// TODO: the Prospects table in sharefile-ui has a `maxSelectableProspect`
	// that they check for before adding to the selection set. If we decide to
	// do the same, check that we won't exceed that number here
	return [...contacts, contact];
}

function contactsToProspects(
	contacts: ContactRecord[] | undefined,
	account: Account,
	integrationId: string
): Prospect[] | undefined {
	if (!contacts) {
		return;
	}

	return contacts.map(c => ({
		...c,
		'odata.type': 'ShareFile.Api.Models.Prospect',
		Id: globalIdForRecord(c, integrationId), // TODO: remove this once the workflow branches based on integrationId
		Account: account,
		integrationId,
	}));
}

const _ContactRecordsTable = (props: Props) => {
	const setSelectedContacts = props.setSelectedContacts ?? (() => {});
	const {
		integrationId,
		isLoading,
		contacts,
		selectedContacts,
		onWorkflowsTriggered,
		workflowStatus,
		setSearchTerm,
		searchTerm,
	} = props;

	const piral = usePiralContext();
	const { account } = useUserState();

	const selectedProspects = React.useMemo(
		() => contactsToProspects(selectedContacts, account as Account, integrationId),
		[selectedContacts, account, integrationId]
	);

	const triggerIdsForSelectedContacts = props.triggerIdsForSelectedContacts ?? [];
	const handleWorkflowSuccess = async (data?: WorkflowTrigger[]) => {
		if (!data || !onWorkflowsTriggered || !selectedContacts) {
			return;
		}
		onWorkflowsTriggered(selectedContacts, data);
	};

	// if the PiralApi is undefined, it means we're rendering
	// in a Storybook, so we render a mock button component
	// instead of the Piral ExtensionSlot
	const workflowExtensionSlot =
		piral === undefined ? (
			<MockProspectWorkflowExtension
				inputs={props.selectedContacts ?? []}
				actionName={t('integrations:actions.send_agreement')}
				onAction={() =>
					logger.logInfo(
						'workflow trigger button pressed, but workflows not available in current env'
					)
				}
			/>
		) : (
			<piral.Extension
				name="sharefile_ui-prospective_client-context_action"
				params={
					{
						prospects: selectedProspects ?? [],
						triggerIds: triggerIdsForSelectedContacts,
						onSuccess: handleWorkflowSuccess,
						// TODO: remove when sharefile-ui is updated to accept triggerIds as a parameter
					} as {
						prospects: Prospect[];
						triggerIds: string[];
						onSuccess: (data?: WorkflowTrigger[]) => void;
					}
				}
			/>
		);

	const contextBarContent = selectedContacts !== undefined && (
		<StyledButtonContainer>{workflowExtensionSlot}</StyledButtonContainer>
	);

	const selectContact = (contact: ContactRecord, checked: boolean) => {
		setSelectedContacts(toggleSelected(props.selectedContacts ?? [], contact, checked));
		pendoSendTrackEvent('Integrations - Select contact', {
			integrationId,
		});
	};

	const contactValidation = (contact: ContactRecord) => {
		return !contact.Email;
	};
	const rowForContact = (contact: ContactRecord) => {
		const isSelected = selectedContacts?.some(c => equalIds(c, contact));

		const name = [contact.LastName, contact.FirstName].join(', ');

		const status = workflowStatus ? workflowStatus(contact) : WorkflowStatus.Unknown;

		const statusBadge = status === WorkflowStatus.InProgress && (
			<InProgressBadge>
				{/* TODO: localization */}
				Agreement Sent
			</InProgressBadge>
		);

		const checkbox = (
			<Checkbox
				checked={isSelected ?? false}
				disabled={contactValidation(contact)}
				onChange={toggleState => selectContact(contact, !toggleState)}
			/>
		);

		return (
			<TR key={contact.externalRecordId}>
				<TD>
					<Tooltip
						hoverTrigger={checkbox}
						isDisabled={!contactValidation(contact)}
						placement="top"
					>
						Agreement may not be sent to user for the user doesn't have email specified.
					</Tooltip>
				</TD>
				<TD label={t('integrations:table_labels.name')}>
					<StyledEllipsisElement>
						<Avatar contact={contact} size={30}>
							<Button.TextSecondary>{name}</Button.TextSecondary>
						</Avatar>
					</StyledEllipsisElement>
				</TD>
				<TD label={t('integrations:table_labels.email')}>
					<StyledTableDataElement>{contact.Email}</StyledTableDataElement>
				</TD>
				<TD label={t('integrations:table_labels.phone')}>
					<StyledTableDataElement>{contact.Phone}</StyledTableDataElement>
				</TD>
				<TD>{statusBadge}</TD>
			</TR>
		);
	};

	const showEmptyState = !isLoading && contacts.length === 0;

	const tableHeader = (
		<THead>
			<TH width="20px"></TH>
			<TH>{t('integrations:table_labels.name')}</TH>
			<TH>{t('integrations:table_labels.email')}</TH>
			<TH>{t('integrations:table_labels.phone')}</TH>
			<TH width="160px">
				{/* TODO: button to reveal additional fields? possibly integration-specific... */}
			</TH>
		</THead>
	);

	const tableBody = isLoading ? (
		<TBody>
			<TR>
				<TD>
					<EmptyStateLoaderPlaceHolder
						aria-label={t('integrations:contact_records.loading_contacts')}
					/>
				</TD>
			</TR>
			<TR>
				<TD>
					<LoadingOverlaySecondary />
				</TD>
			</TR>
		</TBody>
	) : (
		<TBody>{contacts.map(rowForContact)}</TBody>
	);

	// TODO: localization
	const emptyStateLabel = t('integrations:contact_records.empty_records');
	const table = showEmptyState ? (
		<EmptyState icon={EmptyPeopleIcon}>
			<span aria-label={emptyStateLabel}>{emptyStateLabel}</span>
		</EmptyState>
	) : (
		<Table>
			{tableHeader}
			{tableBody}
		</Table>
	);

	return (
		<>
			<StyledTableContainer>
				<StyledActions>
					<StyledSearchContent>
						<StyledSearchInput
							disabled={isLoading}
							value={searchTerm}
							placeholder={t('integrations:contact_records.search_prospects')}
							width={220}
							onChange={setSearchTerm}
						/>
					</StyledSearchContent>
					<StyledContextBarContainer>{contextBarContent}</StyledContextBarContainer>
				</StyledActions>
				<TableContainer header={props.header}>{table}</TableContainer>
			</StyledTableContainer>
		</>
	);
};

export const ContactRecordsTable = withModals(_ContactRecordsTable);
