import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

/**
 * A higher-order component that wraps the given `Component` with a
 * react-query QueryClientProvider. All components wrapped with this
 * function will use the same query client.
 *
 * @param Component A React component (class or function)
 * @returns
 */
export function withQueryContext<
	T extends JSX.IntrinsicAttributes & { children?: React.ReactNode }
>(Component: React.ComponentType<T>) {
	return (props: T) => (
		<QueryClientProvider client={queryClient}>
			<Component {...props} />
		</QueryClientProvider>
	);
}
