import styled from '@emotion/styled';
import { styleUtils } from '@sharefiledev/flow-web';

export type AvatarSize = 24 | 30 | 50 | 60 | 75;

const commonStyles = (size: AvatarSize) => {
	let fontSize: number;
	let spacing: number;

	switch (size) {
		case 24:
			fontSize = 11;
			spacing = 10;
			break;
		case 30:
			fontSize = 12;
			spacing = 12;
			break;
		case 50:
			fontSize = 24;
			spacing = 15;
			break;
		case 60:
			fontSize = 21;
			spacing = 20;
			break;
		case 75:
			fontSize = 32;
			spacing = 20;
			break;
	}

	return {
		fontSize,
		spacing,
	};
};

export const AvatarContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

export const AvatarNameContainer = styled.div(({ size }: { size: AvatarSize }) => {
	const { spacing } = commonStyles(size);
	return {
		flexGrow: 1,
		...styleUtils.ellipsis,
		marginLeft: `${spacing}px`,
	};
});

export const AvatarCircle = styled.div<{ size: AvatarSize }>`
	flex-shrink: 0;
	border-radius: 50%;
	background-color: #d8d8d8;
	text-align: center;
	overflow: hidden;
	position: relative;
	width: ${props => `${props.size}px`};
	height: ${props => `${props.size}px`};
`;

export const AvatarInitials = styled.div(({ size }: { size: AvatarSize }) => {
	const { fontSize } = commonStyles(size);
	return {
		position: 'absolute',
		lineHeight: `${size}px`,
		fontSize: `${fontSize}px`,
		width: `${size}px`,
	};
});
