import { getFeatureFlagValue } from '@citrite/feature-flags';
import axios from 'axios';
import { FeatureFlag } from '../sdk';
import { Integration } from './models';

const isLocal =
	process.env.NODE_ENV === 'development' && !process.env.INTEGRATIONS_SKIP_LOCAL_API;

const headers: { [key: string]: string } = {
	'X-BFF-CSRF': `csrf-${Math.random()}`,
};

if (isLocal) {
	if (!process.env.LOCAL_JWT_TOKEN) {
		console.error(
			'No JWT! Run `npm run mintjwt` from the api repo and `export LOCAL_JWT_TOKEN=<output>` from the shell where you start the pilet.'
		);
	}
	headers['Authorization'] = `Bearer ${process.env.LOCAL_JWT_TOKEN}`;
}

export const isProduction = () => process.env.NODE_ENV === 'production';

export const httpClient = (version?: string) =>
	axios.create({
		baseURL: getBaseURL(version),
		headers,
	});

export function getBaseURL(version?: string): string {
	// When developing locally, target the API on the local machine by default

	const APIversion = version || 'v1';
	return isLocal
		? `http://localhost:8080/api/${APIversion}`
		: `/io/integrations/api/${APIversion}`;

	// The "bff" reverse proxy in the app shell will redirect requests with this path prefix
	// to the API instance for the current env (test, staging, prod, etc.)
	// Note: depends on https://code.citrite.net/projects/WAPP/repos/sharefile-ui/pull-requests/2120/overview
	// being merged
}

export function endpoint(path: string, queryParams?: Record<string, string>): string {
	if (path.startsWith('/')) {
		path = path.substring(1);
	}

	const url = `${getBaseURL()}/${path}`;
	if (queryParams) {
		const q = new URLSearchParams(queryParams);
		return `${url}?${q}`;
	}

	return url;
}

export const filterConnectors = (integrations: Integration[]): Integration[] => {
	if (isProduction()) {
		return getFeatureFlagValue(FeatureFlag.EnableStripeConnector)
			? integrations
			: integrations.filter(integration => integration.name.toLowerCase() !== 'stripe');
	}
	return integrations;
};
