import React from 'react';
import { LoadingPrimary } from '@sharefiledev/flow-web';
import {
	EmptyParams,
	ExtensionImplementationProps,
	SearchContactsExtensionParams,
} from 'sdk/extensionTypes';
import { ConnectedEntityAutocompleteExtensionParams } from '../sdk';
import { Props as CreateInlineConnectionButtonProps } from './Buttons/CreateInlineConnection';

const LazyConnectionManagerExtensionSlot = React.lazy(
	() => import('./ConnectionManager')
);
export const AsyncConnectionManagerExtensionSlot: React.FC = _props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyConnectionManagerExtensionSlot />
		</React.Suspense>
	);
};

const LazyPersonalConnectionManagerExtensionSlot = React.lazy(
	() => import('./ConnectionsTable/ConnectionsDashboard')
);

export const AsyncPersonalConnectionManagerExtensionSlot: React.FC = _props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyPersonalConnectionManagerExtensionSlot />
		</React.Suspense>
	);
};

const LazyCatalogPreviewExtensionSlot = React.lazy(() => import('./CatalogPreview'));
export const AsyncCatalogPreviewExtensionSlot: React.FC = _props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyCatalogPreviewExtensionSlot />
		</React.Suspense>
	);
};

const LazyConnectedEntityAutoCompleteExtensionSlot = React.lazy(
	() => import('./ConnectedEntityAutoComplete')
);
export const AsyncConnectedEntityAutoCompleteExtensionSlot: React.FC<
	ConnectedEntityAutocompleteExtensionParams
> = props => {
	const {
		placeholder,
		size,
		connectedEntityRID,
		defaultValue,
		style,
		onSelect,
		onBlur,
		onError,
		onClear,
	} = props;
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyConnectedEntityAutoCompleteExtensionSlot
				placeholder={placeholder}
				size={size}
				onSelect={onSelect}
				onBlur={onBlur}
				onError={onError}
				onClear={onClear}
				defaultValue={defaultValue}
				connectedEntityRID={connectedEntityRID}
				style={style}
			/>
		</React.Suspense>
	);
};

const LazyConnectedButtonExtensionSlot = React.lazy(() => import('./ConnectButton'));
export const AsyncConnectButtonExtensionSlot: React.FC<
	ExtensionImplementationProps<EmptyParams>
> = props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyConnectedButtonExtensionSlot piral={props.piral} />
		</React.Suspense>
	);
};

const LazyImportContactModalExtensionSlot = React.lazy(
	() => import('./SearchContacts/ImportContactModal')
);
export const AsyncImportContactModalExtensionSlot: React.FC<
	SearchContactsExtensionParams
> = props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyImportContactModalExtensionSlot {...props} />
		</React.Suspense>
	);
};

const LazyCreateInlineConnectionButtonExtensionSlot = React.lazy(
	() => import('./Buttons/CreateInlineConnection')
);
export const AsyncCreateInlineConnectionButtonExtensionSlot: React.FC<
	CreateInlineConnectionButtonProps
> = props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyCreateInlineConnectionButtonExtensionSlot {...props} />
		</React.Suspense>
	);
};
