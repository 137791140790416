import { hookstate } from '@hookstate/core';
import type {
	Account,
	PiletApi,
	PiletUserContext,
} from '@sharefiledev/sharefile-appshell';
import { usePiralContext } from '../context/withPiralContext';

const emptyUserState: PiletUserContext = { user: {}, account: {} };
const testUserState = hookstate(emptyUserState);

export function setTestUserState(state: PiletUserContext) {
	if (process.env.NODE_ENV === 'production') {
		console.error(
			'setTestUserState should only be called in a test or storybook context'
		);
		return;
	}
	testUserState.set(state);
}

export function getPiletUserInfo(piral: PiletApi | null | undefined) {
	if (!piral) {
		if (process.env.NODE_ENV !== 'production') {
			const testUserInfo = testUserState.get();
			return { ...testUserInfo };
		}
		return emptyUserState;
	}
	let userInfo: PiletUserContext | undefined;
	try {
		userInfo = piral.sf.piletUserContext.get();
	} catch (err) {
		console.error('error accessing pilet user context:', err);
	}

	if (!userInfo) {
		console.warn('pilet user context unavailable, returning empty user state');
		return emptyUserState;
	}
	return userInfo;
}

export function useUserState() {
	const piral = usePiralContext();

	return getPiletUserInfo(piral);
}

export function useRequiredAccountId() {
	const { account } = useUserState();

	if (!account.Id) {
		throw new Error('No account id available in the current context.');
	}
	return account.Id;
}

export function useRequiredAccountName() {
	const { account } = useUserState();

	if (!account.CompanyName) {
		throw new Error('No account name available in the current context.');
	}
	return account.CompanyName;
}

/**
 * Returns a name for the given account, to be passed along to cyclr
 * for display in the Cyclr UI.
 *
 * @returns account.CompanyName if non-null, otherwise falls back to
 * `ShareFile Account: ${account.Id}`
 *
 * @throws if both `account.CompanyName` and `account.Id` are null.
 */
export function getAccountName(account: Account): string {
	if (account.CompanyName) {
		return account.CompanyName;
	}
	if (!account.Id) {
		throw new Error('Unable to determine name for account object');
	}
	return `ShareFile Account: ${account.Id}`;
}
