import axios from 'axios';
import { ContactRecord, globalIdForRecord } from './models';

const ROUTES = {
	getTriggers: '/prospects/workflows/triggers',

	createOrDeleteTrigger: (id: string) => `/prospects(${id})/workflows/triggers`,
};

export type WorkflowTrigger = {
	ProspectId: string;
	TriggerId: string;
};

interface WorkflowTriggerResult {
	WorkflowTriggers: WorkflowTrigger[];
}

function authenticatedClient() {
	const token = window.sessionStorage.getItem('OAuthToken');
	return axios.create({
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Returns a list of workflow triggers associated with the given record ids.
 */
export async function getWorkflowTriggers(ids: string[]): Promise<WorkflowTrigger[]> {
	if (!ids || ids.length === 0) {
		return [];
	}

	// partition the ids into batches, to avoid sending more than will fit into
	// the query string of the request.
	const batchSize = 10;
	const promises: Promise<WorkflowTrigger[]>[] = [];
	for (let i = 0; i < ids.length; i += batchSize) {
		const batch = ids.slice(i, i + batchSize);
		const prospectIds = batch.join(',');
		const query = new URLSearchParams({ prospectIds });
		const url = `${ROUTES.getTriggers}?${query}`;
		const client = authenticatedClient();
		const promise = client
			.get<WorkflowTriggerResult>(url)
			.then(result => result.data.WorkflowTriggers ?? []);
		promises.push(promise);
	}

	const results = await Promise.all(promises);
	return results.flatMap(r => r);
}

/**
 * Associates a record id with a workflow trigger id.
 */
export async function storeWorkflowTrigger(input: WorkflowTrigger): Promise<void> {
	const { TriggerId, ProspectId } = input;
	const query = new URLSearchParams({ TriggerId });
	const url = `${ROUTES.createOrDeleteTrigger(ProspectId)}?${query}`;
	const client = authenticatedClient();
	await client.post(url);
}

export async function storeTriggersForContacts(
	contacts: ContactRecord[],
	triggers: WorkflowTrigger[],
	integrationId: string
) {
	const byId = Object.fromEntries(triggers.map(t => [t.ProspectId, t]));
	const resourceIds = contacts.map(c => globalIdForRecord(c, integrationId));
	const promises = resourceIds.map(id => {
		const trigger = byId[id];
		return trigger && storeWorkflowTrigger(trigger);
	});

	await Promise.all(promises);
}

export async function fetchTriggersForContacts(
	contacts: ContactRecord[],
	integrationId: string
) {
	const ids = contacts.map(c => globalIdForRecord(c, integrationId));
	return getWorkflowTriggers(ids);
}
