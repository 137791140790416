import React, { useEffect, useState } from 'react';
import { ModalFuncProps } from 'antd';
import { ModalContext } from '../../context';

export interface ConfirmationProps
	extends Omit<ModalFuncProps, 'duration' | 'closeIcon'> {
	type: 'success' | 'info' | 'warning' | 'error';
}

interface Props {
	children?: React.ReactNode;
}

const ModalProvider = ({ children }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

	const openModal = (content: React.ReactNode | null) => {
		setModalContent(content);
		setIsModalOpen(true);
	};

	const closeModal = () => setIsModalOpen(false);

	const clearModal = () => setModalContent(null);

	return (
		<ModalContext.Provider
			value={{
				isModalOpen,
				openModal,
				closeModal,
				clearModal,
			}}
		>
			{children}
			{modalContent}
		</ModalContext.Provider>
	);
};

export default ModalProvider;
