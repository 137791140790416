export interface CallbackMessage {
	caller: string;
	action: string;
	data: any;
}

export interface Connection {
	name: string;
	ownerId: string;
	dateCreated: string;
	status: Status;
	integrationId: string;
	rid: string;
	shareCount?: number;
	connectionLogo?: string;
}

export interface ConnectionProperty {
	Name: string;
	Value: string;
}

export interface ConnectorProperty {
	name: string;
	values: ConnectorPropertyValues[];
	description: string;
	friendlyName: string;
	type: string;
	id: number;
	triggerName: string;
	triggerMethodId: number | null;
}

export interface ConnectorPropertyValues {
	label: string;
	value: string;
}

export type Trigger = {
	triggerId: number;
	methodId: number;
	name: string;
};

export interface ConnectionPropertiesResponse {
	properties: ConnectorProperty[];
	connection: Connection;
}

export interface FormViews {
	[key: string]: FormView;
}

interface FormView {
	title?: string;
	component: React.ComponentType<any>;
}

export interface InitiateNewConnectionResponse {
	connectionRID: string | null;
	// preAuthenticationConnectorProperties: ConnectorProperty[] | null;
	// postAuthenticationConnectorProperties: ConnectorProperty[] | null;
	properties: ConnectorProperty[];
	url: string | null;
}

export type ConnectConnectionResponse = Omit<
	InitiateNewConnectionResponse,
	'connectionRID'
>;

export interface Integration {
	id: string;
	logo: string;
	name: string;
	status?: Status;
	connectorId: number;
}

export enum Status {
	Enabled = 'Enabled',
	Disabled = 'Disabled',
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	Error = 'Error',
}

export enum ValidationStatus {
	NotValidated = 'NotValidated',
	ValidationSuccess = 'ValidationSuccess',
	ValidationError = 'ValidationError',
}

export enum PollingStatus {
	Stopped = 'Stopped',
	InProgress = 'InProgress',
	Completed = 'Completed',
}
