import React from 'react';
import { ThemeColor, useThemeColor } from '@sharefiledev/flow-web';
import { ContactRecord } from '../../api/models';
import {
	AvatarCircle,
	AvatarContainer,
	AvatarInitials,
	AvatarNameContainer,
	AvatarSize,
} from './Avatar.styled';

export interface Props {
	children?: React.ReactNode;
	size: AvatarSize;
	contact: ContactRecord;
}

export const Avatar = (props: Props) => {
	const { contact, size, children } = props;

	const primaryTextColor = useThemeColor(ThemeColor.primaryText);

	let initials = contact.Email?.charAt(0).toUpperCase() ?? '';
	if (contact.FirstName || contact.LastName) {
		const first = contact.FirstName?.charAt(0).toUpperCase() ?? '';
		const last = contact.LastName?.charAt(0).toLocaleUpperCase() ?? '';
		initials = `${first}${last}`;
	}

	return (
		<AvatarContainer>
			<AvatarCircle size={size}>
				<AvatarInitials style={{ color: primaryTextColor }} size={size}>
					{initials}
				</AvatarInitials>
			</AvatarCircle>
			{children && <AvatarNameContainer size={size}>{children}</AvatarNameContainer>}
		</AvatarContainer>
	);
};
