const formatDate = (timestamp?: string) => {
	const currentDate = timestamp ? new Date(timestamp) : new Date();
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const day = String(currentDate.getDate()).padStart(2, '0');
	const year = currentDate.getFullYear();

	return `${month}/${day}/${year}`;
};

export const formatTime = (timestamp?: string) => {
	const date = timestamp ? new Date(timestamp) : new Date();
	const formattedTime = date.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	return formattedTime;
};

export default formatDate;
