import { createContext } from 'react';
import { TriggerNotificationProps } from '../components/Providers/Notification';

interface NotificationContextType {
	triggerNotification(props: TriggerNotificationProps): void;
}

const defaultState = {
	triggerNotification: () => {},
};

const NotificationContext = createContext<NotificationContextType>(defaultState);

export default NotificationContext;
