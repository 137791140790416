import { createScopedInstance, getLanguage } from '@citrite/translate';
import { languages } from './locales/';

export { getLanguage };
const instance = createScopedInstance({
	resources: languages,
	namespace: 'integrations',
});

export const { t } = instance;
