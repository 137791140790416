import { extend, hookstate, useHookstate } from '@hookstate/core';
import { localstored } from '@hookstate/localstored';
import { subscribable } from '@hookstate/subscribable';
import { a } from 'msw/lib/glossary-de6278a9';
import { getAccountIntegrations } from '../api/account-integrations';
import { getAllIntegrations } from '../api/integrations';
import { AccountIntegration, Integration, IntegrationsResult } from '../api/models';

export type IntegrationStatus = {
	isEnabled: boolean;
};

export type IntegrationWithStatus = Integration & IntegrationStatus;

/**
 * A global state hook that holds the list of all Integrations
 * fetched from the API, including those not enabled for the account.
 *
 * Use {@link fetchIntegrations} to trigger the API call that updates
 * the state.
 */
export const integrationsState = hookstate({} as IntegrationsResult, subscribable());

/**
 * A global state hook that holds the list of AccountIntegrations for
 * the current account, as fetched from the API.
 *
 * Use {@link fetchAccountIntegrations} to trigger the API call that updates
 * the state.
 */
export const accountIntegrationsState = hookstate(
	[] as AccountIntegration[],
	extend(subscribable(), localstored({ key: 'account-integrations' }))
);

/**
 * Requests the list of Integrations from the API and updates the
 * state returned by the {@link useAllIntegrations} hook.
 */
export function fetchIntegrations() {
	integrationsState.set(getAllIntegrations());
}

/**
 * Requests the list of AccountIntegrations from the API and updates the
 * state returned by the {@link useAccountIntegrations} hook.
 */
export function fetchAccountIntegrations(accountId: string, src?: string) {
	accountIntegrationsState.set(getAccountIntegrations(accountId, src));
}

/**
 * A React hook that provides a hookstate containing the list of all
 * Integrations provided by the API. This includes integrations that
 * are not enabled for the current account.
 *
 * Use {@link fetchIntegrations} to trigger the API call that updates
 * the state.
 */
export function useAllIntegrations() {
	return useHookstate(integrationsState);
}

/**
 * A React hook that provides a hookstate containing the list of
 * AccountIntegration objects provided by the API for the current
 * account id. The API returns one AccountIntegraton object for
 * each Integration that's been enabled for the current account.
 *
 * Use {@link fetchAccountIntegrations} to trigger the API call that updates
 * the state.
 */
export function useAccountIntegrations() {
	return useHookstate(accountIntegrationsState);
}

/**
 * A React hook that decorates the list of Integrations returned by
 * {@link useAllIntegrations} with a field (`isEnabled`) that indicates
 * whether the Integration is enabled for the current account.
 *
 * Uses the {@link useAccountIntegrations} hook to determine which
 * integrations are enabled.
 *
 * To refresh the state used by this hook, call both
 * {@link fetchIntegrations} and {@link fetchAccountIntegrations}.
 */
export function useIntegrationsWithStatus() {
	const all = useAllIntegrations();
	const accountIntegrations = useAccountIntegrations();

	if (all.promised || accountIntegrations.promised) {
		return { promised: true as const };
	}

	if (all.error) {
		return { error: all.error, promised: false as const };
	}

	if (accountIntegrations.error) {
		return { error: accountIntegrations.error, promised: false as const };
	}

	const enabledIds = new Set(accountIntegrations.get().map(ai => ai.integrationId));

	const results: IntegrationWithStatus[] = all.get().results?.map(i => ({
		...i,
		isEnabled: enabledIds.has(i.integrationId),
	}));

	return {
		results,
		roles: all.get().roles,
		promised: false as const,
		error: undefined,
	};
}
