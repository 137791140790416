import { getFeatureFlagValue } from '@citrite/feature-flags';
import { AccountPreferences } from '@citrite/sf-api';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { AST as IntegrationAST } from './asts';
import { Entitlement } from './entitlements';
import { FeatureFlag } from './featureFlags';

type AccountPreferencesWithIntegrationsAST = AccountPreferences & {
	EnableThirdPartyIntegrations?: boolean;
};
type AST = keyof AccountPreferencesWithIntegrationsAST;
const requiredAST = IntegrationAST.EnableThirdPartyIntegrations as AST;

export function shouldDisplayIntegrations(app: PiletApi, featureFlag?: FeatureFlag) {
	try {
		const userContext = app.sf.piletUserContext.get();

		// PLG overrides standard AST / FF checks
		if (getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth)) {
			const { entitlements }: PiletUserContext = userContext;
			return !!entitlements.getEntitlementStatus(Entitlement.IntegrationsInstall);
		} else {
			const preferences = userContext.account.Preferences || {};
			const astCheck = !!preferences[requiredAST];
			return featureFlag ? astCheck && getFeatureFlagValue(featureFlag) : astCheck;
		}
	} catch (err) {
		return false;
	}
}
