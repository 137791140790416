import { createContext } from 'react';
import { ModalProps } from 'antd';

interface ModalContextType {
	isModalOpen: boolean;
	clearModal(): void;
	closeModal(): void;
	openModal(Component: React.JSX.Element, props?: ModalProps): void;
}

const defaultState = {
	isModalOpen: false,
	clearModal: () => {},
	closeModal: () => {},
	openModal: () => {},
};

const ModalContext = createContext<ModalContextType>(defaultState);

export default ModalContext;
