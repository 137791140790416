import React from 'react';
import ModalProvider from './Modal';
import NotificationProvider from './Notification';

interface Props {
	children?: React.ReactNode;
}

const IntegrationsProvider = ({ children }: Props) => (
	<NotificationProvider>
		<ModalProvider>{children}</ModalProvider>
	</NotificationProvider>
);

export default IntegrationsProvider;
