import 'react-loading-skeleton/dist/skeleton.css';
import './styles/tailwind.css';
import React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Flex } from 'antd';
import { Connection } from './api/models';
import {
	AsyncCatalogPreviewExtensionSlot,
	AsyncConnectButtonExtensionSlot,
	AsyncConnectedEntityAutoCompleteExtensionSlot,
	AsyncConnectionManagerExtensionSlot,
	AsyncCreateInlineConnectionButtonExtensionSlot,
	AsyncImportContactModalExtensionSlot,
	AsyncPersonalConnectionManagerExtensionSlot,
} from './components';
import RootProvider from './components/Providers';
import { withAllProviders } from './context';
import { setLogger } from './helpers/logger';
import { AsyncLazyContactsTableTestPage } from './pages';
import { FeatureFlag } from './sdk';
import { integrationsExtensionSlots, legacyExtensionSlots } from './sdk/extensionTypes';
import { setupNavMenuState, waitForValidUserInfo } from './state/navmenu-state';
import { setPiralState } from './state/piral-state';
import formatDate, { formatTime } from './utils/formatDate';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	app.sf.registerSfApiConfigHandler(Api.configure);
	setPiralState(app);

	waitForValidUserInfo().then(userInfo => {
		// if (getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth)) {
		// 	const { entitlements }: PiletUserContext = app.sf.piletUserContext.get();
		// 	if (!entitlements.getEntitlementStatus(Entitlement.IntegrationsInstall)) {
		// 		return;
		// 	}
		// } else {
		// 	if (!checkASTs(userInfo)) {
		// 		return;
		// 	}
		// }

		const unsubscribe = setupNavMenuState();
		app.on('unload-pilet', (e: { name: string }) => {
			if (e.name !== '@sharefiledev/integrations-pilet') {
				return;
			}

			unsubscribe();
		});

		if (shouldRegisterDevRoutes()) {
			app.registerPage(
				'/integrations/dev/contacts-table',
				withAllProviders(AsyncLazyContactsTableTestPage)
			);
		}

		app.registerExtension(
			integrationsExtensionSlots.connectedEntityAutoComplete,
			withAllProviders(({ params }) => {
				const {
					connectedEntityRID,
					defaultValue,
					placeholder,
					size,
					style,
					onSelect,
					onBlur,
					onError,
					onClear,
				} = params;
				return (
					<RootProvider>
						<AsyncConnectedEntityAutoCompleteExtensionSlot
							connectedEntityRID={connectedEntityRID}
							onSelect={onSelect}
							onBlur={onBlur}
							onError={onError}
							onClear={onClear}
							defaultValue={defaultValue}
							placeholder={placeholder}
							size={size}
							style={style}
						/>
					</RootProvider>
				);
			})
		);

		app.registerExtension(
			integrationsExtensionSlots.catalog,
			withAllProviders(() => (
				<RootProvider>
					<AsyncCatalogPreviewExtensionSlot />
				</RootProvider>
			))
		);

		app.registerExtension(integrationsExtensionSlots.createConnection, ({ params }) => {
			if (
				params.entityType !== 'ShareFile/integrations/connection' &&
				params.entityType !== 'ShareFile/integrations/connection_v2'
			) {
				return null;
			}

			const setConnectionValue = (connection: Connection) => {
				// Contract: Sharefile/integrations/connection
				params.setEntityPickerValue({
					Id: connection.connectionRID,
					Name: connection.connectionName,
					ConnectorName: connection.cyclrConnectorName,
					ConnectorVersion: connection.cyclrConnectorVersion,
					ConnectorId: connection.cyclrConnectorId.toString(),
				});
			};

			return (
				<RootProvider>
					<Flex justify="flex-start">
						<AsyncCreateInlineConnectionButtonExtensionSlot
							buttonProps={{
								disabled: params.disabled || false,
							}}
							connectionCallback={setConnectionValue}
							connectorName={String(params.typeArgs?.connectorName) ?? null}
							connectionName={
								params.typeArgs?.autoName && params.typeArgs?.connectorName
									? `${params.typeArgs.connectorName} (${formatDate()} @ ${formatTime()})`
									: undefined
							}
							integrationAPIVersion={params.typeArgs?.integrationAPIVersion}
						/>
					</Flex>
				</RootProvider>
			);
		});

		app.registerExtension(
			integrationsExtensionSlots.connectButton,
			withAllProviders(AsyncConnectButtonExtensionSlot)
		);

		app.registerExtension(
			integrationsExtensionSlots.connectionManager,
			withAllProviders(() => (
				<RootProvider>
					<AsyncConnectionManagerExtensionSlot />
				</RootProvider>
			))
		);

		app.registerExtension(integrationsExtensionSlots.searchContacts, ({ params }) => {
			const turnedOn = getFeatureFlagValue(FeatureFlag.ConnectionManager);
			if (!turnedOn) {
				return null;
			}

			return <AsyncImportContactModalExtensionSlot {...params} />;
		});

		app.registerExtension(
			integrationsExtensionSlots.personalConnectionManager,
			withAllProviders(() => (
				<RootProvider>
					<AsyncPersonalConnectionManagerExtensionSlot />
				</RootProvider>
			))
		);

		// TODO: delete once we update consumers to new extension slot urns
		app.registerExtension(
			legacyExtensionSlots.catalog,
			withAllProviders(() => (
				<RootProvider>
					<AsyncCatalogPreviewExtensionSlot />
				</RootProvider>
			))
		);

		// TODO: delete once we update consumers to new extension slot urns
		app.registerExtension(legacyExtensionSlots.createConnection, ({ params }) => {
			if (
				params.entityType !== 'ShareFile/integrations/connection' &&
				params.entityType !== 'ShareFile/integrations/connection_v2'
			) {
				return null;
			}

			const setConnectionValue = (connection: Connection) => {
				// Contract: Sharefile/integrations/connection
				params.setEntityPickerValue({
					Id: connection.connectionRID,
					Name: connection.connectionName,
					ConnectorName: connection.cyclrConnectorName,
					ConnectorVersion: connection.cyclrConnectorVersion,
					ConnectorId: connection.cyclrConnectorId.toString(),
				});
			};

			return (
				<RootProvider>
					<Flex justify="flex-start">
						<AsyncCreateInlineConnectionButtonExtensionSlot
							buttonProps={{
								disabled: params.disabled || false,
							}}
							connectionCallback={setConnectionValue}
							connectorName={String(params.typeArgs?.connectorName) ?? null}
							connectionName={
								params.typeArgs?.autoName && params.typeArgs?.connectorName
									? `${params.typeArgs.connectorName} (${formatDate()} @ ${formatTime()})`
									: undefined
							}
							integrationAPIVersion={params.typeArgs?.integrationAPIVersion}
						/>
					</Flex>
				</RootProvider>
			);
		});

		// TODO: delete once we update consumers to new extension slot urns
		app.registerExtension(
			legacyExtensionSlots.connectButton,
			withAllProviders(AsyncConnectButtonExtensionSlot)
		);

		// TODO: delete once we update consumers to new extension slot urns
		app.registerExtension(
			legacyExtensionSlots.connectionManager,
			withAllProviders(() => (
				<RootProvider>
					<AsyncConnectionManagerExtensionSlot />
				</RootProvider>
			))
		);

		// TODO: delete once we update consumers to new extension slot urns
		app.registerExtension(legacyExtensionSlots.searchContacts, ({ params }) => {
			const turnedOn = getFeatureFlagValue(FeatureFlag.ConnectionManager);
			if (!turnedOn) {
				return null;
			}
			return <AsyncImportContactModalExtensionSlot {...params} />;
		});
	});
}

function shouldRegisterDevRoutes() {
	const NODE_ENV = process.env.NODE_ENV ?? 'production';
	if (NODE_ENV === 'development') {
		return true;
	}

	if (window.location.hostname === 'employeeshares.sharefile.com') {
		return true;
	}

	return false;
}
