// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.absolute {
    position: absolute
}
.relative {
    position: relative
}
.\\!m-0 {
    margin: 0px !important
}
.m-0 {
    margin: 0px
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}
.\\!mb-0 {
    margin-bottom: 0px !important
}
.\\!mb-3 {
    margin-bottom: 0.75rem !important
}
.mr-4 {
    margin-right: 1rem
}
.mt-3 {
    margin-top: 0.75rem
}
.block {
    display: block
}
.inline-block {
    display: inline-block
}
.flex {
    display: flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.hidden {
    display: none
}
.\\!h-6 {
    height: 1.5rem !important
}
.h-8 {
    height: 2rem
}
.h-\\[6px\\] {
    height: 6px
}
.w-8 {
    width: 2rem
}
.w-\\[6px\\] {
    width: 6px
}
.flex-1 {
    flex: 1 1 0%
}
.flex-shrink {
    flex-shrink: 1
}
.rounded-2xl {
    border-radius: 1rem
}
.border {
    border-width: 1px
}
.border-2 {
    border-width: 2px
}
.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}
.bg-errorRed {
    --tw-bg-opacity: 1;
    background-color: rgb(255 77 79 / var(--tw-bg-opacity))
}
.bg-successGreen {
    --tw-bg-opacity: 1;
    background-color: rgb(40 145 52 / var(--tw-bg-opacity))
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.p-8 {
    padding: 2rem
}
.\\!px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}
.\\!py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}
.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}
.pb-4 {
    padding-bottom: 1rem
}
.pt-3 {
    padding-top: 0.75rem
}
.font-primary {
    font-family: PPMori, sans-serif
}
.\\!text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important
}
.text-base {
    font-size: 16px
}
.text-sm {
    font-size: 14px
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.\\!font-normal {
    font-weight: 400 !important
}
.font-bold {
    font-weight: 700
}
.font-medium {
    font-weight: 500
}
.font-semibold {
    font-weight: 600
}
.italic {
    font-style: italic
}
.\\!leading-10 {
    line-height: 2.5rem !important
}
.leading-6 {
    line-height: 1.5rem
}
.leading-normal {
    line-height: 1.5
}
.leading-tight {
    line-height: 1.25
}
.tracking-wide {
    letter-spacing: 0.025em
}
.text-textMuted {
    --tw-text-opacity: 1;
    color: rgb(97 103 107 / var(--tw-text-opacity))
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
`, "",{"version":3,"sources":["webpack://./src/styles/tailwind.css"],"names":[],"mappings":"AACA;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,eAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,sBAAmB;IAAnB;AAAmB;AAAnB;IAAA,kBAAmB;IAAnB;AAAmB;AAAnB;IAAA,kBAAmB;IAAnB;AAAmB;AAAnB;IAAA,kBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,6BAAmB;IAAnB;AAAmB;AAAnB;IAAA,2BAAmB;IAAnB;AAAmB;AAAnB;IAAA,iBAAmB;IAAnB;AAAmB;AAAnB;IAAA,iBAAmB;IAAnB;AAAmB;AAAnB;IAAA,iBAAmB;IAAnB;AAAmB;AAAnB;IAAA,iBAAmB;IAAnB;AAAmB;AAAnB;IAAA,iBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,8BAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,kBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,oBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,gKAAmB;IAAnB,wJAAmB;IAAnB,iLAAmB;IAAnB,wDAAmB;IAAnB;AAAmB","sourcesContent":["@tailwind components;\n@tailwind utilities;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
