import React from 'react';
import { BaseComponentProps } from '@sharefiledev/sharefile-appshell';
import { withPiralContext } from './withPiralContext';
import { withQueryContext } from './withQueryContext';

export function withAllProviders<T extends BaseComponentProps>(
	Component: React.ComponentType<T>
) {
	return withPiralContext(withQueryContext(Component));
}
