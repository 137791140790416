export interface CollectionResult<T> {
	[x: string]: any;
	results: T[];
}

export interface Connection {
	connectionRID: string;
	cyclrAccountConnectorId: number;
	cyclrConnectorName: string;
	cyclrConnectorVersion?: string;
	cyclrConnectorId: number;
	connectionName: string;
	createdAt: string;
	lastValidatedAt: string;
	ownerRID?: string;
	ownerName?: string;
	shareCount?: number;
}

export interface ReadPackingResourceDTO {
	RID: string;
	state: ConnectedEntity;
}

export interface ConnectedEntity {
	RID: string;
	containerRID: string;
	connectionRID: string;
	provider: string;
	entity: string;
	required: string[];
	properties: {
		[key: string]: Record<string, string | boolean>;
	};
	version: string;
}

export interface Integration {
	integrationId: string;
	connectorId: number;
	name: string;
	description: string;
	tag: string;
	version: string;
	blurb: string;
	logoPath?: string;
	screenshotUrl: string;
	developerName: string;
	developerWebsite: string;
	developerSupportWebsite: string;
	updatedAt: string;
}

export interface IntegrationsResult {
	results: Integration[];
	roles: {
		isAdmin: boolean;
	};
}

export interface AccountIntegration {
	accountId: string;
	integrationId: string;
	name: string;
	description: string;
	tag: string;
	logoPath?: string;
	isEnabled: boolean;
	connectionRID: string;
}

/**
 * Object created by cyclr at the end of the "Launch" flow and posted to the opening window
 * via postMessage.
 *
 * @see https://docs.cyclr.com/handling-callback#javascript-postmessage
 */
export type CyclrLaunchResult = {
	accountId: string;
	accountApiId: string;
	cycleId: string;
	status: 'Active' | 'Stopped';
	userId: string;
	webhooks: string[];
	errors: string[];
	completeParameter?: string;
	templateId: string;
	templateReleaseId: string;
	templateTags: string[];
};
export interface RecordBase {
	/** The id of the ShareFile account this record belongs to */
	accountId: string;

	/** The "native" ID for this record in the system it originated in. */
	externalRecordId: string;
}

export interface ContactRecord extends RecordBase {
	FirstName: string;
	LastName: string;
	Email: string;
	Phone?: string;

	[x: string]: unknown;
}

export interface CollectionResult<T> {
	results: T[];
}

export type GetContactRecordsResult = CollectionResult<ContactRecord>;

/**
 * Returns a globally unique string id for the given record and integration id.
 * Used for the purposes of tracking workflow state.
 *
 *  Should NOT be considered "canonical" - this is not a valid Resource ID,
 *  as those details are TBD.
 */
export function globalIdForRecord(r: RecordBase, integrationId: string) {
	return `${r.accountId}:${integrationId}:${r.externalRecordId}`;
}
