import { createContext } from 'react';
import { Integration, Status } from '../types';

interface IntegrationContextType {
	integration: Integration;
	isLoadingConnections: boolean;
	refreshConnections(): void;
}

const defaultState = {
	integration: {
		id: '',
		logo: '',
		name: '',
		status: Status.Disconnected,
		connectorId: 0,
	},
	isLoadingConnections: false,
	refreshConnections: async () => {},
};

const IntegrationContext = createContext<IntegrationContextType>(defaultState);

export default IntegrationContext;
