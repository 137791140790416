import React from 'react';
import { hookstate } from '@hookstate/core';
import { PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { AccountIntegration } from '../api/models';
import { ContactRecordsBrowser } from '../components/ContactRecordsBrowser';
import { withAllProviders } from '../context';
import { t } from '../translate';
import { accountIntegrationsState, fetchAccountIntegrations } from './integration-state';
import { piralState } from './piral-state';
import { getPiletUserInfo } from './user-state';

interface NavItem {
	accountIntegration: AccountIntegration;
	isEnabled: boolean;
	isRegistered: boolean;
}

/**
 * A map of integrationId -> NavItem for that integration
 */
type NavStates = Record<string, NavItem>;

const navMenuState = hookstate({} as Record<string, NavItem>);

/**
 * A setup hook for the "left nav" menu item states. Should be called in
 * the pilet `setup` entry point.
 *
 * Registers hookstate subscriptions to the integrations and account-integrations
 * state atoms. When either state updates, the list of enabled integrations
 * will be recomputed, and any navigation items that have not yet been registered
 * will be added.
 *
 * @returns a function that can be called to unsubscribe from state updates.
 */
export function setupNavMenuState() {
	// when we first load, we can use the account-integrations state from localstorage
	if (!accountIntegrationsState.promised) {
		const accountIntegrations = accountIntegrationsState.get({ noproxy: true });
		navMenuState.set(currentNavState =>
			updateNavMenuState(currentNavState, accountIntegrations as AccountIntegration[])
		);
	}

	// user info isn't avaliable immediately. when it is, we refresh the account integrations
	// state, which will trigger a new update to the nav menu state
	waitForValidUserInfo().then(userInfo => {
		if (userInfo.account?.Id && userInfo.user?.Id) {
			fetchAccountIntegrations(userInfo.account.Id, 'setupNavMenuState');
		} else {
			throw new Error('waitForValidUserInfo returned invalid user info :( Please fix...');
		}
	});

	const unsubscribeAccountIntegrations = accountIntegrationsState.subscribe(
		accountIntegrations => {
			navMenuState.set(currentNavState =>
				updateNavMenuState(
					currentNavState,
					// the accountIntegrations subscription callback gets passed
					// fancy hookstate proxy objects instead of the plain JS
					// accountIntegrations objects. This causes a
					// [HOOKSTATE-103](https://hookstate.js.org/docs/exceptions#hookstate-103) error
					// if you try to access the value while a fetch happens to be in flight.
					// To avoid this, we make a copy of each object's enumerable properties,
					// so `updateNavMenu` state can access them without worrying about async issues
					// when using the proxy.
					accountIntegrations.map(proxyObject => ({ ...proxyObject }))
				)
			);
		}
	);

	return () => {
		unsubscribeAccountIntegrations();
	};
}

export function isValidPiralState(state: { user: any; account: any }) {
	const { user, account } = state;
	if (!account?.Id) {
		return false;
	}

	if (!user) {
		return false;
	}

	if (user.FirstName === 'Anonymous' && user.LastName === 'Anonymous') {
		return false;
	}

	return true;
}

export async function waitForValidUserInfo(delayMs = 300): Promise<PiletUserContext> {
	const piral = piralState.get({ noproxy: true });
	const userState = getPiletUserInfo(piral);
	if (!isValidPiralState(userState)) {
		await new Promise(resolve => setTimeout(resolve, delayMs));
		return waitForValidUserInfo(delayMs);
	}
	return userState as PiletUserContext;
}

/**
 * Update the nav menu state, taking the current state and list of AccountIntegrations as input.
 *
 */
export function updateNavMenuState(
	current: NavStates,
	accountIntegrations: AccountIntegration[]
) {
	const newState: NavStates = {};
	const needsRegistration: AccountIntegration[] = [];

	for (const accountIntegration of accountIntegrations) {
		const { integrationId } = accountIntegration;
		let item = current[integrationId];
		if (!item) {
			item = {
				accountIntegration,
				isEnabled: accountIntegration.isEnabled,
				isRegistered: false,
			};

			needsRegistration.push(accountIntegration);
		}
		item.isEnabled = accountIntegration.isEnabled;
		newState[integrationId] = item;
	}

	// if there are any entries in the current state that
	// are not present in accountIntegrations, we want to
	// remove the entry from the nav menu. we do so by
	// setting the state for that entry to
	// { isEnabled: false, isRegistered: true }
	// If we simply remove the state entry instead, we'll
	// forget that we've already registered it in the case
	// where an integration is uninstalled and then re-installed.
	for (const integrationId of Object.keys(current)) {
		if (!(integrationId in newState)) {
			newState[integrationId] = {
				...current[integrationId],
				isEnabled: false,
			};
		}
	}

	if (needsRegistration.length > 0) {
		registerNavItems(needsRegistration);
		for (const ai of needsRegistration) {
			newState[ai.integrationId].isRegistered = true;
		}
	}

	return newState;
}

/**
 * Given an account integration, return the route for the "contacts browser" page.
 */
function routeForIntegration(ai: AccountIntegration): string {
	return `/integrations/${ai.integrationId}/users`;
}

/**
 * Given an account integration, return the text to show in the left nav menu.
 */
function navTitleForIntegration(ai: AccountIntegration): string {
	return `${ai.name} (Beta)`;
}

/**
 * Registers nav menu items for the given account integrations. Called
 * from {@link updateNavMenuState}.
 */
export function registerNavItems(accountIntegrations: AccountIntegration[]) {
	const piletApi = piralState.get({ noproxy: true });
	if (!piletApi) {
		console.warn(t('errors:pilet_error'));
		return;
	}

	for (const accountIntegration of accountIntegrations) {
		const { integrationId, name } = accountIntegration;
		const route = routeForIntegration(accountIntegration);
		// register nav menu item
		piletApi.sf.registerLeftNavChildComponent({
			parent: '/users',
			title: () => navTitleForIntegration(accountIntegration),
			href: route,

			// the isAvailable function will always fetch the current nav menu state,
			// so if an integration is disabled after registration, it will disappear
			// from the nav the next time isAvailable is called
			isAvailable: () => {
				const currentState = navMenuState.get();
				const enabled = currentState[integrationId]?.isEnabled || false;
				return enabled;
			},
		});

		// register the route
		piletApi.registerPage(
			route,
			withAllProviders(() => {
				const currentState = navMenuState.get();
				const currentItem = currentState[integrationId];
				if (!currentItem || !currentItem.isEnabled) {
					// TODO show better error state
					return <div>Integration not enabled</div>;
				}
				const { connectionRID } = currentItem.accountIntegration;
				return (
					<ContactRecordsBrowser
						connectionRID={connectionRID}
						integrationId={integrationId}
						title={name}
					/>
				);
			})
		);
	}
}
