import { hookstate, useHookstate } from '@hookstate/core';
import { subscribable } from '@hookstate/subscribable';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';

export const piralState = hookstate(null as PiletApi | null, subscribable());

export function setPiralState(piletApi: PiletApi) {
	piralState.set(piletApi);
}

export function usePiral() {
	return useHookstate(piralState).get({ noproxy: true });
}
