import { httpClient } from './helpers';
import { IntegrationsResult } from './models';

const client = httpClient('v1');
export async function getAllIntegrations(): Promise<IntegrationsResult> {
	const url = '/integrations';
	const { data: integrations } = await client.get<IntegrationsResult>(url);
	return integrations;
}

export async function getSelectableIntegrations(): Promise<
	{ label: string; value: string; logoPath: string; description: string }[]
> {
	const { results: integrations } = await getAllIntegrations();

	return integrations.map(integration => ({
		label: integration.name.split(' ')[0],
		value: integration.connectorId.toString(),
		logoPath: integration.logoPath || '',
		description: integration.description || '',
	}));
}
