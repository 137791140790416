import React from 'react';
import { LoadingPrimary } from '@sharefiledev/flow-web';

const LazyContactsTableTestPage = React.lazy(() => import('./ContactsTableTestPage'));
export const AsyncLazyContactsTableTestPage: React.FC = _props => {
	return (
		<React.Suspense fallback={<LoadingPrimary />}>
			<LazyContactsTableTestPage />
		</React.Suspense>
	);
};
