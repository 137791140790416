import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, SearchInput } from '@sharefiledev/flow-web';

export const StyledTableContainer = styled.div`
	font-size: 14px;
`;

const TableDataElementHeight = css`
	line-height: 32px;
`;

const EllipsisOverflowStyle = css`
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const StyledTableDataElement = styled.span`
	${TableDataElementHeight}
`;

export const StyledEllipsisElement = styled.div`
	${EllipsisOverflowStyle}
`;

export const StyledButtonContainer = styled.div`
	${TableDataElementHeight}
	background-color: white;
	float: right;
`;

export const EmptyStateLoaderPlaceHolder = styled.div`
	height: 120px;
	position: relative;
`;

export const MaxProspectsInfoAlertContainer = styled.div(() => ({
	float: 'right',
}));

export const StyledSecondaryButton = styled(Button.Secondary)`
	div {
		line-height: 10px;
	}
`;

export const StyledSearchInput = styled(SearchInput)`
	border: '1px solid rgb(177, 177, 177)';
`;

export const StyledActions = styled.div(() => ({
	display: 'flex',
}));

export const StyledSearchContent = styled.div({
	marginBottom: '20px',
	display: 'inline-block',
});

export const StyledContextBarContainer = styled.div({
	display: 'inline-block',
	marginRight: 'auto',
	flex: '1',
	paddingTop: '6px',
});
