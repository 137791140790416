/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'ko' | 'nl' | 'pt-BR' | 'ru' | 'zh-CN';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "actions": {
        "loading": "Laden...",
        "send_agreement": "Kundenvereinbarung senden"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "Hinzufügen",
        "details": "Details",
        "uninstall": "Deinstallieren"
      },
      "connected_entity_search": {
        "no_results": "Keine Ergebnisse für Ihre Suche",
        "searching": "Suchen läuft"
      },
      "connection_manager": {
        "authenticate": "Authentifizieren",
        "cancel": "Abbrechen",
        "connect": "Verbinden",
        "connected": "Verbunden über '{{connectionName}}'",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Verbindungen",
        "continue": "Weiter",
        "copy": "Verwalten Sie die kontoweiten Verbindungen, die für dieses Konto erstellt wurden.",
        "create_connection": "Verbindung erstellen",
        "create_connection_failed": "Fehler beim Erstellen von '{{connectionName}}'.",
        "created": "'{{connectionName}}' wurde erstellt",
        "created_by": "Erstellt von {{ownerName}} am {{dateCreated}}.",
        "created_on": "Erstellt am {{dateCreated}}.",
        "delete": "Löschen",
        "delete_connection": "{{connectionName}} löschen",
        "delete_connection_copy": "Möchten Sie '{{connectionName}}' wirklich löschen? Alle Ressourcen, die diese Verbindung verwenden, benötigen dann eine neue Verbindung, um weiterhin funktionieren zu können.",
        "delete_connection_failed": "Fehler beim Löschen von '{{connectionName}}'.",
        "deleted_connection_successfully": "'{{connectionName}}' wurde gelöscht.",
        "disable": "Deaktivieren",
        "disconnect": "Trennen",
        "disconnect_connection": "'{{connectionName}}' trennen",
        "disconnect_connection_copy": "Möchten Sie die Verbindung '{{connectionName}}' wirklich trennen? Alle Ressourcen, die diese Verbindung verwenden, werden erst wieder funktionieren, wenn sie wiederhergestellt ist.",
        "disconnect_connection_failed": "Fehler beim Trennen von '{{connectionName}}'.",
        "disconnected_connection_successfully": "'{{connectionName}}' wurde getrennt.",
        "edit": "Bearbeiten",
        "edit_connection": "Verbindung bearbeiten",
        "new_connection": "Neue Verbindung",
        "no_connections": "Keine Verbindungen",
        "reconnecting_connection": "Verbindung wird wiederhergestellt",
        "required_connection_options": "Erforderliche Optionen für die Verbindung",
        "save": "Speichern",
        "update_connection_failed": "Fehler beim Aktualisieren von '{{connectionName}}'.",
        "updated_connection_successfully": "'{{connectionName}}' wurde aktualisiert.",
        "validate_connection_failed": "Fehler beim Validieren der Verbindung.",
        "validating_connection": "Verbindung wird validiert"
      },
      "connection_manager_actions": {
        "connect": "Verbinden",
        "delete": "Löschen",
        "details": "Details",
        "disconnect": "Trennen",
        "manage_access": "Zugriff verwalten",
        "rename": "Umbenennen",
        "selected": "ausgewählt",
        "share": "Freigeben"
      },
      "connection_manager_delete": {
        "delete": "Löschen",
        "description1": "Möchten Sie '{{connectionName}}' wirklich löschen? ",
        "description2": "Sie können diese Verbindung dann nicht mehr für Integrationen verwenden.",
        "title": "Verbindung löschen"
      },
      "connection_manager_disconnect": {
        "description": "Dadurch werden die Authentifizierungsdetails und alle Connectoreinstellungen gelöscht. Geräte, die diese Verbindung verwenden, funktionieren nicht mehr, bis die Verbindung wiederhergestellt ist.",
        "disconnect": "Trennen",
        "title": "Möchten Sie '{{connectionName}}' wirklich trennen?"
      },
      "connection_manager_drawer": {
        "alert_info": "Standardmäßig sind Sie der Einzige, der die von Ihnen erstellte Verbindung nutzen kann.",
        "catalog": "Katalog durchsuchen",
        "description": "Hier können Sie eine Verbindung zu verfügbaren Diensten herstellen. Sie können mehrere Verbindungen zum gleichen Dienst herstellen.",
        "footer_description": "Sie finden nicht, was Sie brauchen? Durchsuchen Sie den ShareFile-Katalog nach weiteren Möglichkeiten zur Erweiterung Ihres Kontos!",
        "footer_title": "Auf der Suche nach mehr?",
        "services_header": "Verfügbare Services"
      },
      "connection_manager_page": {
        "description": "Die Funktionalität von ShareFile kann mit externen Diensten erweitert werden. Auf dieser Seite können Sie die Verbindungen Ihres Kontos mit diesen Diensten verwalten.",
        "title": "Verbindungen"
      },
      "connection_manager_rename": {
        "cancel": "Abbrechen",
        "error": "Geben Sie einen gültigen Verbindungsnamen ein",
        "form_title": "Verbindung umbenennen",
        "label": "Verbindungsname",
        "placeholder": "Neuen Namen für die Verbindung eingeben",
        "save": "Speichern",
        "updating_connection": "Verbindung wird aktualisiert"
      },
      "connection_manager_tab": {
        "my_connections": "Eigene Verbindungen",
        "shared_with_me": "Für mich freigegeben"
      },
      "connection_manager_table": {
        "actions_message": "Wählen Sie Elemente aus, um Aktionen anzuzeigen",
        "created_by": "Erstellt von",
        "date_added": "Hinzugefügt am",
        "name": "Name",
        "no_connections_header": "Willkommen bei Ihren Verbindungen",
        "no_connections_message": "Sie haben keine Verbindungen zu externen Diensten erstellt. Sie benötigen eine Verbindung, um Integrationen mit einem Dienst nutzen zu können.",
        "no_shared_connections": "Keine freigegebenen Verbindungen",
        "selected": "ausgewählt",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Erstellt von",
        "created_on": "Erstellt am"
      },
      "connections_permission": {
        "delete": "Verbindung löschen",
        "entity": "Verbindung",
        "modify": "Verbindung ändern",
        "preset": {
          "member": "Mitglied",
          "owner": "Eigentümer"
        },
        "read": "Verbindung lesen",
        "share": "Verbindung freigeben"
      },
      "connections_share": {
        "remove_success": "Erfolgreich entfernt.",
        "share_success": "Ausgewählte Verbindungen wurden erfolgreich freigegeben."
      },
      "connections_status": {
        "connected": "Verbunden",
        "disconnected": "Getrennt"
      },
      "contact_records": {
        "empty_records": "Keine passenden Datensätze",
        "error_message": "Fehler bei der Kontaktsuche. Wenden Sie sich an Ihren Administrator, um die Drittanbieterverbindung wiederherzustellen.",
        "loading_contacts": "Kontakte werden geladen...",
        "search_prospects": "Interessenten suchen"
      },
      "details_modal": {
        "developer": "Entwickler",
        "last_updated": "Zuletzt aktualisiert",
        "support": "Support",
        "version": "Version"
      },
      "errors": {
        "pilet_error": "Pilet-API nicht verfügbar, Navigationselemente werden nicht registriert"
      },
      "import": {
        "import_button": "Importieren",
        "import_prospect": "Interessenten importieren",
        "search": "Suchen",
        "search_with_dots": "Suchen...",
        "select_connection": "Verbindung auswählen:",
        "unknown_option": "Unbekannte Option"
      },
      "notifications": {
        "install": "Integration installiert",
        "uninstall": "Integration deinstalliert",
        "warning_message": "Sind Sie sicher, dass Sie diese Integration vollständig deinstallieren möchten? Dadurch werden alle integrierten Daten aus Ihrem ShareFile-Konto entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden."
      },
      "table_labels": {
        "email": "E-Mail",
        "name": "Name",
        "phone": "Telefon"
      },
      "third_party_app": {
        "navigation_description": "Integrationen werden in den ShareFile-Katalog verschoben. Dadurch können wir unser Angebot erweitern und Ihnen ein besseres Benutzererlebnis bieten. Unsere wachsenden Integrationsangebote finden Sie ab sofort im Katalog.",
        "navigation_message": "Integrationen von Drittanbietern werden verschoben",
        "open_catalog": "Katalog öffnen"
      }
    }
  },
  {
    "key": "en",
    "content": {
      "actions": {
        "loading": "Loading...",
        "send_agreement": "Send Client Agreement"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "Add",
        "details": "Details",
        "uninstall": "Uninstall"
      },
      "connected_entity_search": {
        "no_results": "No results found for your search",
        "searching": "Searching"
      },
      "connection_manager": {
        "authenticate": "Authenticate",
        "cancel": "Cancel",
        "connect": "Connect",
        "connected": "Connected \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connections",
        "continue": "Continue",
        "copy": "Manage the account-wide connections that have been created for this account.",
        "create_connection": "Create connection",
        "create_connection_failed": "Failed to create \"{{connectionName}}\".",
        "created": "Created \"{{connectionName}}\"",
        "created_by": "Created by {{ownerName}} on {{dateCreated}}.",
        "created_on": "Created on {{dateCreated}}.",
        "delete": "Delete",
        "delete_connection": "Delete {{connectionName}}",
        "delete_connection_copy": "Are you sure you want to delete \"{{connectionName}}\"? Anything using this connection will require a new connection to continue working.",
        "delete_connection_failed": "Failed to delete \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" deleted successfully.",
        "disable": "Disable",
        "disconnect": "Disconnect",
        "disconnect_connection": "Disconnect \"{{connectionName}}\"",
        "disconnect_connection_copy": "Are you sure you want to disconnect \"{{connectionName}}\"? Anything using this connection will stop working until it is reconnected.",
        "disconnect_connection_failed": "Failed to disconnect \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" disconnected successfully.",
        "edit": "Edit",
        "edit_connection": "Edit Connection",
        "new_connection": "New connection",
        "no_connections": "No connections",
        "reconnecting_connection": "Reconnecting Connection",
        "required_connection_options": "Required connection options",
        "save": "Save",
        "update_connection_failed": "Failed to update \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" updated successfully.",
        "validate_connection_failed": "Failed to validate connection.",
        "validating_connection": "Validating Connection"
      },
      "connection_manager_actions": {
        "connect": "Connect",
        "delete": "Delete",
        "details": "Details",
        "disconnect": "Disconnect",
        "manage_access": "Manage Access",
        "rename": "Rename",
        "selected": "selected",
        "share": "Share"
      },
      "connection_manager_delete": {
        "delete": "Delete",
        "description1": "Are you sure you want to delete \"{{connectionName}}\"? ",
        "description2": "You will no longer be able to use this connection with any integrations.",
        "title": "Delete connection"
      },
      "connection_manager_disconnect": {
        "description": "This will delete authentication details and all connector settings. Anything using this connection will stop working until it is reconnected.",
        "disconnect": "Disconnect",
        "title": "Are you sure you want to disconnect \"{{connectionName}}\" connection?"
      },
      "connection_manager_drawer": {
        "alert_info": "By default, you are the only one who can use the connection you create.",
        "catalog": "Browse catalog",
        "description": "Here you can create a connection to available services. You can create multiple connections to the same service.",
        "footer_description": "Not seeing what you need? Explore the ShareFile catalog to find more ways to expand your account!",
        "footer_title": "Looking for more?",
        "services_header": "Available Services"
      },
      "connection_manager_page": {
        "description": "ShareFile's functionality can be expanded with external services. This page allows you to manage your account connections with these services.",
        "title": "Connections"
      },
      "connection_manager_rename": {
        "cancel": "Cancel",
        "error": "Please enter a valid connection name",
        "form_title": "Rename Connection",
        "label": "Connection Name",
        "placeholder": "Enter a new name for the connection",
        "save": "Save",
        "updating_connection": "Updating Connection"
      },
      "connection_manager_tab": {
        "my_connections": "My Connections",
        "shared_with_me": "Shared with me"
      },
      "connection_manager_table": {
        "actions_message": "Select items to see actions",
        "created_by": "Created By",
        "date_added": "Date Added",
        "name": "Name",
        "no_connections_header": "Welcome to your connections",
        "no_connections_message": "You haven't created any connections to external services. You'll need a connection to make use of integrations with a service.",
        "no_shared_connections": "No shared connections",
        "selected": "selected",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Created by",
        "created_on": "Created on"
      },
      "connections_permission": {
        "delete": "Delete connection",
        "entity": "Connection",
        "modify": "Modify connection",
        "preset": {
          "member": "Member",
          "owner": "Owner"
        },
        "read": "Read Connection",
        "share": "Share connection"
      },
      "connections_share": {
        "remove_success": "Removed successfully.",
        "share_success": "Selected connections shared successfully."
      },
      "connections_status": {
        "connected": "Connected",
        "disconnected": "Disconnected"
      },
      "contact_records": {
        "empty_records": "No matching records",
        "error_message": "Error searching contacts. Please contact your admin to re-establish the third-party connection.",
        "loading_contacts": "Loading contacts...",
        "search_prospects": "Search Prospects"
      },
      "details_modal": {
        "developer": "Developer",
        "last_updated": "Last Updated",
        "support": "Support",
        "version": "Version"
      },
      "errors": {
        "pilet_error": "pilet api not available, not registering nav items"
      },
      "import": {
        "import_button": "Import",
        "import_prospect": "Import Prospect",
        "search": "Search",
        "search_with_dots": "Search...",
        "select_connection": "Select Connection:",
        "unknown_option": "Unknown option"
      },
      "notifications": {
        "install": "Integration installed",
        "uninstall": "Integration uninstalled",
        "warning_message": "Are you sure you want to completely uninstall this integration? This will remove all integrated data from your ShareFile account. This process cannot be reversed."
      },
      "table_labels": {
        "email": "Email",
        "name": "Name",
        "phone": "Phone"
      },
      "third_party_app": {
        "navigation_description": "Integrations are moving to the ShareFile Catalog. This will allow us to expand our offerings and provide a better experience for you. To see our growing integrations offerings, check out the catalog from now on.",
        "navigation_message": "Third party integrations are moving",
        "open_catalog": "Open catalog"
      }
    }
  },
  {
    "key": "es",
    "content": {
      "actions": {
        "loading": "Cargando...",
        "send_agreement": "Enviar contrato del cliente"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "Agregar",
        "details": "Detalles",
        "uninstall": "Desinstalar"
      },
      "connected_entity_search": {
        "no_results": "No se encontraron resultados para su búsqueda",
        "searching": "Buscando"
      },
      "connection_manager": {
        "authenticate": "Autenticarse",
        "cancel": "Cancelar",
        "connect": "Conectar",
        "connected": "Conectado \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Conexiones",
        "continue": "Continuar",
        "copy": "Administrar las conexiones que se han creado específicamente para esta cuenta.",
        "create_connection": "Crear conexión",
        "create_connection_failed": "Error al crear \"{{connectionName}}\".",
        "created": "Creado \"{{connectionName}}\"",
        "created_by": "Creación por {{ownerName}} el {{dateCreated}}.",
        "created_on": "Creación el {{dateCreated}}.",
        "delete": "Eliminar",
        "delete_connection": "Eliminar {{connectionName}}",
        "delete_connection_copy": "¿Seguro que quiere eliminar \"{{connectionName}}\"? Todo lo que utilice esta conexión requerirá una nueva conexión para seguir funcionando.",
        "delete_connection_failed": "Error al eliminar \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" se eliminó correctamente.",
        "disable": "Inhabilitar",
        "disconnect": "Desconectar",
        "disconnect_connection": "Desconectar \"{{connectionName}}\"",
        "disconnect_connection_copy": "¿Seguro que quiere desconectar \"{{connectionName}}\"? Todo lo que utilice esta conexión dejará de funcionar hasta que se conecte de nuevo.",
        "disconnect_connection_failed": "Error al desconectar \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" se desconectó correctamente.",
        "edit": "Modificar",
        "edit_connection": "Modificar conexión",
        "new_connection": "Nueva conexión",
        "no_connections": "Sin conexiones",
        "reconnecting_connection": "Reconectando la conexión",
        "required_connection_options": "Opciones de conexión requeridas",
        "save": "Guardar",
        "update_connection_failed": "Error al actualizar \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" se actualizó correctamente.",
        "validate_connection_failed": "Error al validar la conexión.",
        "validating_connection": "Validando la conexión"
      },
      "connection_manager_actions": {
        "connect": "Conectar",
        "delete": "Eliminar",
        "details": "Detalles",
        "disconnect": "Desconectar",
        "manage_access": "Administrar acceso",
        "rename": "Cambiar nombre",
        "selected": "seleccionado(s)",
        "share": "Compartir"
      },
      "connection_manager_delete": {
        "delete": "Eliminar",
        "description1": "¿Seguro que quiere eliminar \"{{connectionName}}\"? ",
        "description2": "Ya no podrá usar esta conexión con ninguna integración.",
        "title": "Eliminar conexión"
      },
      "connection_manager_disconnect": {
        "description": "Se eliminarán los detalles de autenticación y todos los parámetros del conector. Todo lo que use esta conexión dejará de funcionar hasta que se conecte de nuevo.",
        "disconnect": "Desconectar",
        "title": "¿Seguro que quiere desconectar la conexión \"{{connectionName}}\"?"
      },
      "connection_manager_drawer": {
        "alert_info": "De forma predeterminada, usted es el único que puede usar la conexión que cree.",
        "catalog": "Examinar catálogo",
        "description": "Aquí puede crear una conexión con los servicios disponibles. Puede crear varias conexiones con el mismo servicio.",
        "footer_description": "¿No ve lo que necesita? Explore el catálogo de ShareFile para encontrar más formas de ampliar su cuenta.",
        "footer_title": "¿Busca más opciones?",
        "services_header": "Servicios disponibles"
      },
      "connection_manager_page": {
        "description": "La funcionalidad de ShareFile se puede ampliar con servicios externos. Esta página le permite administrar las conexiones de su cuenta con estos servicios.",
        "title": "Conexiones"
      },
      "connection_manager_rename": {
        "cancel": "Cancelar",
        "error": "Introduzca un nombre de conexión válido",
        "form_title": "Cambiar nombre de conexión",
        "label": "Nombre de la conexión",
        "placeholder": "Introduzca un nuevo nombre para la conexión",
        "save": "Guardar",
        "updating_connection": "Actualizando la conexión"
      },
      "connection_manager_tab": {
        "my_connections": "Mis conexiones",
        "shared_with_me": "Compartidos conmigo"
      },
      "connection_manager_table": {
        "actions_message": "Seleccionar elementos para ver las acciones",
        "created_by": "Creado por",
        "date_added": "Fecha de inserción",
        "name": "Nombre",
        "no_connections_header": "Le damos la bienvenida a sus conexiones",
        "no_connections_message": "No ha creado ninguna conexión con servicios externos. Necesitará una conexión para hacer uso de las integraciones con un servicio.",
        "no_shared_connections": "No hay conexiones compartidas",
        "selected": "seleccionado(s)",
        "status": "Estado"
      },
      "connections_details": {
        "created_by": "Creado por",
        "created_on": "Creadas el"
      },
      "connections_permission": {
        "delete": "Eliminar conexión",
        "entity": "Conexión",
        "modify": "Modificar conexión",
        "preset": {
          "member": "Miembro",
          "owner": "Propietario"
        },
        "read": "Leer conexión",
        "share": "Compartir conexión"
      },
      "connections_share": {
        "remove_success": "Eliminación completada correctamente.",
        "share_success": "Las conexiones seleccionadas se han compartido correctamente."
      },
      "connections_status": {
        "connected": "Conectado",
        "disconnected": "Desconectadas"
      },
      "contact_records": {
        "empty_records": "No hay coincidencia de registros",
        "error_message": "Error al buscar contactos. Contacte con su administrador para restablecer la conexión con terceros.",
        "loading_contacts": "Cargando contactos...",
        "search_prospects": "Buscar clientes potenciales"
      },
      "details_modal": {
        "developer": "Desarrollador",
        "last_updated": "Última actualización",
        "support": "Asistencia técnica",
        "version": "Versión"
      },
      "errors": {
        "pilet_error": "api de pilet no disponible, no se registran elementos de navegación"
      },
      "import": {
        "import_button": "Importar",
        "import_prospect": "Importar cliente potencial",
        "search": "Buscar",
        "search_with_dots": "Buscar...",
        "select_connection": "Seleccione una conexión:",
        "unknown_option": "Opción desconocida"
      },
      "notifications": {
        "install": "Integración instalada",
        "uninstall": "Integración desinstalada",
        "warning_message": "¿Seguro que quiere desinstalar completamente esta integración? Se eliminarán todos los datos integrados de su cuenta de ShareFile. Este proceso no se puede revertir."
      },
      "table_labels": {
        "email": "Correo electrónico",
        "name": "Nombre",
        "phone": "Teléfono"
      },
      "third_party_app": {
        "navigation_description": "Las integraciones se están trasladando al catálogo de ShareFile. Esto nos permitirá ampliar nuestra oferta y ofrecerle una experiencia mejor. Para ver nuestra creciente oferta de integraciones, consulte el catálogo a partir de ahora.",
        "navigation_message": "Las integraciones de terceros se están trasladando",
        "open_catalog": "Abrir catálogo"
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "actions": {
        "loading": "Chargement...",
        "send_agreement": "Envoyer l'accord client"
      },
      "beta": "(Bêta)",
      "button_labels": {
        "add": "Ajouter",
        "details": "Détails",
        "uninstall": "Désinstaller"
      },
      "connected_entity_search": {
        "no_results": "Aucun résultat n'a été trouvé pour votre recherche",
        "searching": "Recherche en cours"
      },
      "connection_manager": {
        "authenticate": "S'authentifier",
        "cancel": "Annuler",
        "connect": "Connecter",
        "connected": "Connexion \"{{connectionName}}\" connectée",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connexions",
        "continue": "Continuer",
        "copy": "Gérez les connexions à l'échelle du compte qui ont été créées pour ce compte.",
        "create_connection": "Créer une connexion",
        "create_connection_failed": "Impossible de créer la connexion \"{{connectionName}}\".",
        "created": "Connexion \"{{connectionName}}\" créée",
        "created_by": "Créée par {{ownerName}} le {{dateCreated}}.",
        "created_on": "Créée le {{dateCreated}}.",
        "delete": "Supprimer",
        "delete_connection": "Supprimer {{connectionName}}",
        "delete_connection_copy": "Êtes-vous sûr de vouloir supprimer la connexion \"{{connectionName}}\" ? Tout ce qui utilise cette connexion nécessitera une nouvelle connexion pour fonctionner.",
        "delete_connection_failed": "Impossible de supprimer \"{{connectionName}}\".",
        "deleted_connection_successfully": "Connexion \"{{connectionName}}\" supprimée.",
        "disable": "Désactiver",
        "disconnect": "Déconnecter",
        "disconnect_connection": "Déconnecter \"{{connectionName}}\"",
        "disconnect_connection_copy": "Êtes-vous sûr de vouloir déconnecter \"{{connectionName}}\" ? Tout ce qui utilise cette connexion cessera de fonctionner jusqu'à ce qu'elle soit reconnectée.",
        "disconnect_connection_failed": "Impossible de déconnecter \"{{connectionName}}\".",
        "disconnected_connection_successfully": "Connexion \"{{connectionName}}\" déconnectée.",
        "edit": "Modifier",
        "edit_connection": "Modifier la connexion",
        "new_connection": "Nouvelle connexion",
        "no_connections": "Aucune connexion",
        "reconnecting_connection": "Rétablissement de la connexion",
        "required_connection_options": "Options de connexion requises",
        "save": "Enregistrer",
        "update_connection_failed": "Impossible de mettre à jour \"{{connectionName}}\".",
        "updated_connection_successfully": "Connexion \"{{connectionName}}\" mise à jour.",
        "validate_connection_failed": "Impossible de valider la connexion.",
        "validating_connection": "Validation de la connexion"
      },
      "connection_manager_actions": {
        "connect": "Connecter",
        "delete": "Supprimer",
        "details": "Détails",
        "disconnect": "Déconnecter",
        "manage_access": "Gérer l’accès",
        "rename": "Renommer",
        "selected": "sélectionné(s)",
        "share": "Partager"
      },
      "connection_manager_delete": {
        "delete": "Supprimer",
        "description1": "Êtes-vous sûr de vouloir supprimer \"{{connectionName}}\" ? ",
        "description2": "Vous ne pourrez plus utiliser cette connexion avec aucune intégration.",
        "title": "Supprimer la connexion"
      },
      "connection_manager_disconnect": {
        "description": "Les détails d'authentification et tous les paramètres du connecteur seront supprimés. Tout ce qui utilise cette connexion cessera de fonctionner jusqu'à ce que la connexion soit rétablie.",
        "disconnect": "Déconnecter",
        "title": "Êtes-vous sûr de vouloir supprimer la connexion \"{{connectionName}}\" ?"
      },
      "connection_manager_drawer": {
        "alert_info": "Par défaut, vous êtes le seul à pouvoir utiliser la connexion que vous créez.",
        "catalog": "Parcourir le catalogue",
        "description": "Ici, vous pouvez créer une connexion aux services disponibles et plusieurs connexions au même service.",
        "footer_description": "Vous ne voyez pas ce dont vous avez besoin ? Explorez le catalogue ShareFile pour trouver d'autres façons d'étendre votre compte !",
        "footer_title": "Vous recherchez autre chose ?",
        "services_header": "Services disponibles"
      },
      "connection_manager_page": {
        "description": "Les fonctionnalités de ShareFile peuvent être étendues avec des services externes. Cette page vous permet de gérer les connexions de votre compte avec ces services.",
        "title": "Connexions"
      },
      "connection_manager_rename": {
        "cancel": "Annuler",
        "error": "Entrez un nom de connexion valide",
        "form_title": "Renommer la connexion",
        "label": "Nom de la connexion",
        "placeholder": "Entrez un nouveau nom pour la connexion",
        "save": "Enregistrer",
        "updating_connection": "Mise à jour de la connexion"
      },
      "connection_manager_tab": {
        "my_connections": "Mes connexions",
        "shared_with_me": "Partagés avec moi"
      },
      "connection_manager_table": {
        "actions_message": "Sélectionnez des éléments pour voir les actions",
        "created_by": "Créé par",
        "date_added": "Date ajoutée",
        "name": "Nom",
        "no_connections_header": "Bienvenue dans vos connexions",
        "no_connections_message": "Vous n'avez créé aucune connexion à des services externes. Vous aurez besoin d'une connexion pour utiliser les intégrations avec un service.",
        "no_shared_connections": "Aucune connexion partagée",
        "selected": "sélectionné(s)",
        "status": "État"
      },
      "connections_details": {
        "created_by": "Créé par",
        "created_on": "Créé le"
      },
      "connections_permission": {
        "delete": "Supprimer la connexion",
        "entity": "Connexion",
        "modify": "Modifier connexions",
        "preset": {
          "member": "Membre",
          "owner": "Propriétaire"
        },
        "read": "Lire connexions",
        "share": "Partager connexions"
      },
      "connections_share": {
        "remove_success": "Supprimées avec succès.",
        "share_success": "Connexions sélectionnées partagées avec succès."
      },
      "connections_status": {
        "connected": "Connecté",
        "disconnected": "Déconnecté"
      },
      "contact_records": {
        "empty_records": "Aucun enregistrement correspondant",
        "error_message": "Erreur lors de la recherche de contacts. Contactez l'administrateur pour rétablir la connexion tierce.",
        "loading_contacts": "Chargement des contacts...",
        "search_prospects": "Rechercher des prospects"
      },
      "details_modal": {
        "developer": "Développeur",
        "last_updated": "Dernière mise à jour",
        "support": "Assistance",
        "version": "Version"
      },
      "errors": {
        "pilet_error": "API pilet non disponible. Les éléments de navigation ne sont pas enregistrés"
      },
      "import": {
        "import_button": "Importer",
        "import_prospect": "Importer un prospect",
        "search": "Rechercher",
        "search_with_dots": "Recherche...",
        "select_connection": "Sélectionnez une connexion :",
        "unknown_option": "Option inconnue"
      },
      "notifications": {
        "install": "Intégration installée",
        "uninstall": "Intégration désinstallée",
        "warning_message": "Êtes-vous sûr de vouloir désinstaller complètement cette intégration ? Cela supprimera toutes les données intégrées de votre compte ShareFile. Ce processus est irréversible."
      },
      "table_labels": {
        "email": "E-mail",
        "name": "Nom",
        "phone": "Téléphone"
      },
      "third_party_app": {
        "navigation_description": "Les intégrations sont en cours de migration vers le catalogue ShareFile. Cela nous permet d'élargir nos offres et de vous offrir une meilleure expérience. Pour voir toutes nos offres d'intégrations, consultez dès maintenant notre catalogue continuellement enrichi.",
        "navigation_message": "Migration des intégrations de tierces parties",
        "open_catalog": "Ouvrir le catalogue"
      }
    }
  },
  {
    "key": "it",
    "content": {
      "actions": {
        "loading": "Caricamento...",
        "send_agreement": "Invia contratto cliente"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "Aggiungi",
        "details": "Dettagli",
        "uninstall": "Disinstalla"
      },
      "connected_entity_search": {
        "no_results": "Nessun risultato trovato per la ricerca",
        "searching": "Ricerca"
      },
      "connection_manager": {
        "authenticate": "Autentica",
        "cancel": "Annulla",
        "connect": "Connetti",
        "connected": "Connessione: \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connessioni",
        "continue": "Continua",
        "copy": "Gestisci le connessioni a livello di account che sono state create per questo account.",
        "create_connection": "Crea connessione",
        "create_connection_failed": "Impossibile creare \"{{connectionName}}\".",
        "created": "Creazione: \"{{connectionName}}\"",
        "created_by": "Creato da {{ownerName}} il {{dateCreated}}.",
        "created_on": "Creazione: {{dateCreated}}.",
        "delete": "Elimina",
        "delete_connection": "Elimina {{connectionName}}",
        "delete_connection_copy": "Eliminare \"{{connectionName}}\"? Qualsiasi elemento che utilizzi questa connessione richiederà una nuova connessione per continuare a funzionare.",
        "delete_connection_failed": "Impossibile eliminare \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" eliminato correttamente.",
        "disable": "Disabilita",
        "disconnect": "Disconnetti",
        "disconnect_connection": "Disconnetti \"{{connectionName}}\"",
        "disconnect_connection_copy": "Disconnettere \"{{connectionName}}\"? Qualsiasi elemento che utilizzi questa connessione smetterà di funzionare fino a una nuova connessione.",
        "disconnect_connection_failed": "Impossibile disconnettere \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" disconnesso correttamente.",
        "edit": "Modifica",
        "edit_connection": "Modifica connessione",
        "new_connection": "Nuova connessione",
        "no_connections": "Nessuna connessione",
        "reconnecting_connection": "Riconnessione della connessione",
        "required_connection_options": "Opzioni di connessione richieste",
        "save": "Salva",
        "update_connection_failed": "Impossibile aggiornare \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" aggiornato correttamente.",
        "validate_connection_failed": "Impossibile convalidare la connessione.",
        "validating_connection": "Convalida della connessione"
      },
      "connection_manager_actions": {
        "connect": "Connetti",
        "delete": "Elimina",
        "details": "Dettagli",
        "disconnect": "Disconnetti",
        "manage_access": "Gestisci accesso",
        "rename": "Rinomina",
        "selected": "selezionato",
        "share": "Condividi"
      },
      "connection_manager_delete": {
        "delete": "Elimina",
        "description1": "Eliminare \"{{connectionName}}\"? ",
        "description2": "Non sarà più possibile utilizzare questa connessione con alcuna integrazione.",
        "title": "Elimina connessione"
      },
      "connection_manager_disconnect": {
        "description": "In questo modo verranno eliminati i dettagli di autenticazione e tutte le impostazioni del connettore. Tutto ciò che utilizza questa connessione smetterà di funzionare fino a quando non verrà ricollegato.",
        "disconnect": "Disconnetti",
        "title": "Disconnettere la connessione \"{{connectionName}}\"?"
      },
      "connection_manager_drawer": {
        "alert_info": "Per impostazione predefinita, l'utente è l'unico che può utilizzare la connessione creata.",
        "catalog": "Sfoglia il catalogo",
        "description": "Qui è possibile creare una connessione ai servizi disponibili. È possibile creare più connessioni allo stesso servizio.",
        "footer_description": "Non trovi quello che ti serve? Esplora il catalogo di ShareFile per trovare altri modi per espandere il tuo account!",
        "footer_title": "Cerchi di più?",
        "services_header": "Servizi disponibili"
      },
      "connection_manager_page": {
        "description": "Le funzionalità di ShareFile possono essere ampliate con servizi esterni. Questa pagina consente di gestire le connessioni dell'account con questi servizi.",
        "title": "Connessioni"
      },
      "connection_manager_rename": {
        "cancel": "Annulla",
        "error": "Immettere un nome di connessione valido",
        "form_title": "Rinomina connessione",
        "label": "Nome connessione",
        "placeholder": "Immettere un nuovo nome per la connessione",
        "save": "Salva",
        "updating_connection": "Aggiornamento della connessione"
      },
      "connection_manager_tab": {
        "my_connections": "Le mie connessioni",
        "shared_with_me": "Condiviso con me"
      },
      "connection_manager_table": {
        "actions_message": "Selezionare gli elementi per visualizzare le azioni",
        "created_by": "Creato Da",
        "date_added": "Data di aggiunta",
        "name": "Nome",
        "no_connections_header": "Benvenuto nelle tue connessioni",
        "no_connections_message": "Non sono state create connessioni a servizi esterni. Avrai bisogno di una connessione per utilizzare le integrazioni con un servizio.",
        "no_shared_connections": "Nessuna connessione condivisa",
        "selected": "selezionato",
        "status": "Stato"
      },
      "connections_details": {
        "created_by": "Creato da",
        "created_on": "Data di creazione"
      },
      "connections_permission": {
        "delete": "Elimina connessione",
        "entity": "Connessione",
        "modify": "Modifica connessione",
        "preset": {
          "member": "Membro",
          "owner": "Proprietario"
        },
        "read": "Leggi connessione",
        "share": "Condividi connessione"
      },
      "connections_share": {
        "remove_success": "Rimozione completata.",
        "share_success": "Le connessioni selezionate sono state condivise correttamente."
      },
      "connections_status": {
        "connected": "Connesso",
        "disconnected": "Disconnesso"
      },
      "contact_records": {
        "empty_records": "Nessun record corrispondente",
        "error_message": "Errore durante la ricerca nei contatti. Contattare l'amministratore per ristabilire la connessione di terze parti.",
        "loading_contacts": "Caricamento dei contatti...",
        "search_prospects": "Cerca potenziali clienti"
      },
      "details_modal": {
        "developer": "Sviluppatore",
        "last_updated": "Ultimo aggiornamento",
        "support": "Supporto",
        "version": "Versione"
      },
      "errors": {
        "pilet_error": "API pilet non disponibile; gli elementi di navigazione non vengono registrati"
      },
      "import": {
        "import_button": "Importa",
        "import_prospect": "Importa cliente potenziale",
        "search": "Cerca",
        "search_with_dots": "Ricerca...",
        "select_connection": "Seleziona connessione:",
        "unknown_option": "Opzione sconosciuta"
      },
      "notifications": {
        "install": "Integrazione installata",
        "uninstall": "Integrazione disinstallata",
        "warning_message": "Disinstallare completamente questa integrazione? In questo modo saranno rimossi tutti i dati integrati dal tuo account ShareFile. Questo processo non può essere invertito."
      },
      "table_labels": {
        "email": "Invia e-mail",
        "name": "Nome",
        "phone": "Telefono"
      },
      "third_party_app": {
        "navigation_description": "Le integrazioni verranno spostate nel catalogo ShareFile. Ciò ci consentirà di espandere la nostra offerta e fornire un'esperienza migliore. Per vedere le nostre crescenti offerte di integrazioni, dai un'occhiata al catalogo da ora in poi.",
        "navigation_message": "Le integrazioni di terze parti si stanno spostando",
        "open_catalog": "Apri catalogo"
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "actions": {
        "loading": "読み込んでいます...",
        "send_agreement": "顧客契約を送信する"
      },
      "beta": "(ベータ版)",
      "button_labels": {
        "add": "追加",
        "details": "詳細",
        "uninstall": "アンインストール"
      },
      "connected_entity_search": {
        "no_results": "検索結果が見つかりません",
        "searching": "検索中"
      },
      "connection_manager": {
        "authenticate": "認証",
        "cancel": "キャンセル",
        "connect": "接続",
        "connected": "「{{connectionName}}」に接続しました",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "接続",
        "continue": "続行",
        "copy": "このアカウントに対して作成されたアカウント全体の接続を管理します。",
        "create_connection": "接続を作成する",
        "create_connection_failed": "「{{connectionName}}」の作成に失敗しました。",
        "created": "「{{connectionName}}」を作成しました",
        "created_by": "{{dateCreated}} に {{ownerName}} によって作成されました。",
        "created_on": "作成日: {{dateCreated}}。",
        "delete": "削除",
        "delete_connection": "{{connectionName}} を削除する",
        "delete_connection_copy": "「{{connectionName}}」を削除してもよろしいですか? この接続を使用するものはすべて、引き続き動作するために新しい接続が必要になります。",
        "delete_connection_failed": "「{{connectionName}}」の削除に失敗しました。",
        "deleted_connection_successfully": "「{{connectionName}}」は正常に削除されました。",
        "disable": "無効化",
        "disconnect": "切断",
        "disconnect_connection": "「{{connectionName}}」を切断する",
        "disconnect_connection_copy": "「{{connectionName}}」を切断してもよろしいですか? この接続を使用しているものはすべて、再接続するまで動作を停止します。",
        "disconnect_connection_failed": "「{{connectionName}}」の切断に失敗しました。",
        "disconnected_connection_successfully": "「{{connectionName}}」は正常に切断されました。",
        "edit": "編集",
        "edit_connection": "接続を編集する",
        "new_connection": "新しい接続",
        "no_connections": "接続がありません",
        "reconnecting_connection": "接続を再開しています",
        "required_connection_options": "必要な接続オプション",
        "save": "保存",
        "update_connection_failed": "「{{connectionName}}」の更新に失敗しました。",
        "updated_connection_successfully": "「{{connectionName}}」は正常に更新されました。",
        "validate_connection_failed": "接続を検証できませんでした。",
        "validating_connection": "接続を検証しています"
      },
      "connection_manager_actions": {
        "connect": "接続",
        "delete": "削除",
        "details": "詳細",
        "disconnect": "切断",
        "manage_access": "アクセスの管理",
        "rename": "名前を変更する",
        "selected": "選択済み",
        "share": "共有"
      },
      "connection_manager_delete": {
        "delete": "削除",
        "description1": "「{{connectionName}}」を削除してもよろしいですか? ",
        "description2": "この接続はすべての統合で使用できなくなります。",
        "title": "接続を削除する"
      },
      "connection_manager_disconnect": {
        "description": "これにより、認証の詳細とすべてのコネクタ設定が削除されます。この接続を使用しているものはすべて、再接続されるまで動作しなくなります。",
        "disconnect": "切断",
        "title": "「{{connectionName}}」の接続を切断してもよろしいですか?"
      },
      "connection_manager_drawer": {
        "alert_info": "デフォルトでは、作成した接続を使用できるのは作成者だけです。",
        "catalog": "カタログを参照する",
        "description": "ここで、利用可能なサービスへの接続を作成できます。同じサービスへの複数の接続を作成できます。",
        "footer_description": "必要なものが見つかりませんか? ShareFile カタログをご覧いただき、アカウントを拡張する他の方法を見つけてください。",
        "footer_title": "さらにお探しですか?",
        "services_header": "使用可能なサービス"
      },
      "connection_manager_page": {
        "description": "ShareFile の機能は外部サービスによって拡張できます。このページでは、これらのサービスとのアカウント接続を管理できます。",
        "title": "接続"
      },
      "connection_manager_rename": {
        "cancel": "キャンセル",
        "error": "有効な接続名を入力してください",
        "form_title": "接続名の変更",
        "label": "接続名",
        "placeholder": "接続の新しい名前を入力してください。",
        "save": "保存",
        "updating_connection": "接続を更新中"
      },
      "connection_manager_tab": {
        "my_connections": "自分の接続",
        "shared_with_me": "ユーザーと共有"
      },
      "connection_manager_table": {
        "actions_message": "項目を選択して操作を確認する",
        "created_by": "作成元",
        "date_added": "追加した日付",
        "name": "名前",
        "no_connections_header": "接続へようこそ",
        "no_connections_message": "外部サービスへの接続が作成されていません。サービスとの統合を利用するには接続が必要です。",
        "no_shared_connections": "共有された接続がありません",
        "selected": "選択済み",
        "status": "状態"
      },
      "connections_details": {
        "created_by": "作成者",
        "created_on": "作成日:"
      },
      "connections_permission": {
        "delete": "接続を削除する",
        "entity": "接続",
        "modify": "接続を変更する",
        "preset": {
          "member": "メンバー",
          "owner": "所有者"
        },
        "read": "接続を読み取る",
        "share": "接続を共有する"
      },
      "connections_share": {
        "remove_success": "正常に削除されました。",
        "share_success": "選択した接続が共有されました"
      },
      "connections_status": {
        "connected": "接続",
        "disconnected": "切断済み"
      },
      "contact_records": {
        "empty_records": "一致するレコードはありません",
        "error_message": "連絡先の検索中にエラーが発生しました。サードパーティ接続を再確立するには、管理者にお問い合わせください。",
        "loading_contacts": "連絡先を読み込んでいます...",
        "search_prospects": "見込み客を検索する"
      },
      "details_modal": {
        "developer": "開発者",
        "last_updated": "最終更新",
        "support": "サポート",
        "version": "バージョン"
      },
      "errors": {
        "pilet_error": "Pilet API が利用できないため、ナビゲーション項目が登録されていません"
      },
      "import": {
        "import_button": "インポート",
        "import_prospect": "見込み客をインポートする",
        "search": "検索",
        "search_with_dots": "検索...",
        "select_connection": "接続を選択する:",
        "unknown_option": "不明なオプション"
      },
      "notifications": {
        "install": "統合がインストールされました",
        "uninstall": "統合がアンインストールされました",
        "warning_message": "この統合を完全にアンインストールしてもよろしいですか? これにより、ShareFile アカウントからすべての統合データが削除されます。このプロセスを元に戻すことはできません。"
      },
      "table_labels": {
        "email": "メールアドレス",
        "name": "名前",
        "phone": "スマートフォン"
      },
      "third_party_app": {
        "navigation_description": "統合は ShareFile カタログに移行されます。これにより、提供内容を拡大し、より良いエクスペリエンスを提供できるようになります。さらに進化し続ける当社の統合サービスについては、以降のカタログをご確認ください。",
        "navigation_message": "サードパーティとの統合の移行",
        "open_catalog": "カタログを開く"
      }
    }
  },
  {
    "key": "ko",
    "content": {
      "actions": {
        "loading": "Loading...",
        "send_agreement": "클라이언트 계약 전송"
      },
      "beta": "(베타)",
      "button_labels": {
        "add": "추가",
        "details": "세부 정보",
        "uninstall": "제거"
      },
      "connected_entity_search": {
        "no_results": "No results found",
        "searching": "Searching"
      },
      "connection_manager": {
        "authenticate": "Authenticate",
        "cancel": "Cancel",
        "connect": "Connect",
        "connected": "Connected \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connections",
        "continue": "Continue",
        "copy": "Manage the account-wide connections that have been created for this account.",
        "create_connection": "Create connection",
        "create_connection_failed": "Failed to update \"{{connectionName}}\".",
        "created": "Created \"{{connectionName}}\"",
        "created_by": "Created by {{ownerName}} on {{dateCreated}}.",
        "created_on": "Created on {{dateCreated}}.",
        "delete": "Delete",
        "delete_connection": "Delete {{connectionName}}",
        "delete_connection_copy": "Are you sure you want to delete \"{{connectionName}}\"? Anything using this connection will require a new connection to continue working.",
        "delete_connection_failed": "Failed to delete \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" deleted successfully.",
        "disable": "Disable",
        "disconnect": "Disconnect",
        "disconnect_connection": "Disconnect \"{{connectionName}}\"",
        "disconnect_connection_copy": "Are you sure you want to disconnect \"{{connectionName}}\"? Anything using this connection will stop working until it is reconnected.",
        "disconnect_connection_failed": "Failed to disconnect \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" disconnected successfully.",
        "edit": "Edit",
        "edit_connection": "Edit Connection",
        "new_connection": "New connection",
        "no_connections": "No connections",
        "reconnecting_connection": "Reconnecting Connection",
        "required_connection_options": "Required connection options",
        "save": "Save",
        "update_connection_failed": "Failed to update \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" updated successfully.",
        "validate_connection_failed": "Failed to validate connection.",
        "validating_connection": "Validating Connection"
      },
      "connection_manager_actions": {
        "connect": "Connect",
        "delete": "Delete",
        "details": "Details",
        "disconnect": "Disconnect",
        "manage_access": "Manage Access",
        "rename": "Rename",
        "selected": "selected",
        "share": "Share"
      },
      "connection_manager_delete": {
        "delete": "Delete",
        "description1": "Are you sure you want to delete \"{{connectionName}}\"? ",
        "description2": "You will no longer be able to use this connection with any integrations.",
        "title": "Delete connection"
      },
      "connection_manager_disconnect": {
        "description": "This will delete authentication details and all connector settings. Anything using this connection will stop working until it is reconnected.",
        "disconnect": "Disconnect",
        "title": "Are you sure you want to disconnect \"{{connectionName}}\" connection?"
      },
      "connection_manager_drawer": {
        "alert_info": "By default, you are the only one who can use the connection you create.",
        "catalog": "Browse catalog",
        "description": "Here you can create a connection to available services. You can create multiple connections to the same service.",
        "footer_description": "Not seeing what you need?Explore the ShareFile catalog to find more ways to expand your account!",
        "footer_title": "Looking for more?",
        "services_header": "Available Services"
      },
      "connection_manager_page": {
        "description": "ShareFile's functionality can be expanded with external services.This page allows you to manage your account connections with these services.",
        "title": "Connections"
      },
      "connection_manager_rename": {
        "cancel": "Cancel",
        "error": "Please enter a valid connection name",
        "form_title": "Rename Connection",
        "label": "Connection Name",
        "placeholder": "Enter a new name for the connection",
        "save": "Save",
        "updating_connection": "Updating Connection"
      },
      "connection_manager_tab": {
        "my_connections": "My Connections",
        "shared_with_me": "Shared with me"
      },
      "connection_manager_table": {
        "actions_message": "Select items to see actions",
        "created_by": "Created By",
        "date_added": "Date Added",
        "name": "Name",
        "no_connections_header": "Welcome to your connections",
        "no_connections_message": "YOu haven't created any connections to external services. You'll need a connection to make use of integrations with a service.",
        "no_shared_connections": "No shared connections",
        "selected": "selected",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Created by",
        "created_on": "Created on"
      },
      "connections_permission": {
        "delete": "Delete connection",
        "entity": "Connection",
        "modify": "Modify connection",
        "preset": {
          "member": "Member",
          "owner": "Owner"
        },
        "read": "Read Connection",
        "share": "Share connection"
      },
      "connections_share": {
        "remove_success": "Removed successfully.",
        "share_success": "Selected connections shared successfully."
      },
      "connections_status": {
        "connected": "Connected",
        "disconnected": "Disconnected"
      },
      "contact_records": {
        "empty_records": "일치하는 기록 없음",
        "error_message": "Error searching contacts. Please contact your admin to re-establish the third-party connection.",
        "loading_contacts": "연락처 로드 중 ...",
        "search_prospects": "잠재 고객 검색"
      },
      "details_modal": {
        "developer": "개발자",
        "last_updated": "마지막 업데이트",
        "support": "지원",
        "version": "버전"
      },
      "errors": {
        "pilet_error": "Pilet API를 사용할 수 없습니다. 탐색 항목을 등록하지 않습니다"
      },
      "import": {
        "import_button": "Import",
        "import_prospect": "Import Prospect",
        "search": "Search",
        "search_with_dots": "Search...",
        "select_connection": "Select Connection:",
        "unknown_option": "Unknown option"
      },
      "notifications": {
        "install": "통합 설치됨",
        "uninstall": "통합 제거됨",
        "warning_message": "이 통합을 완전히 제거하시겠습니까? 이렇게 하면 ShareFile 계정에서 통합된 데이터가 모두 제거됩니다. 이 프로세스는 되돌릴 수 없습니다."
      },
      "table_labels": {
        "email": "전자 메일",
        "name": "이름",
        "phone": "전화"
      },
      "third_party_app": {
        "navigation_description": "Integrations are moving to the ShareFile Catalog. This will allow us to expand our offerings and provide a better experience for you. To see our growing integrations offerings, check out the catalog from now on.",
        "navigation_message": "Third party integrations are moving",
        "open_catalog": "Open catalog"
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "actions": {
        "loading": "Laden...",
        "send_agreement": "Klantovereenkomst verzenden"
      },
      "beta": "(Bèta)",
      "button_labels": {
        "add": "Toevoegen",
        "details": "Details",
        "uninstall": "Verwijderen"
      },
      "connected_entity_search": {
        "no_results": "Geen resultaten gevonden voor uw zoekopdracht",
        "searching": "Zoeken"
      },
      "connection_manager": {
        "authenticate": "Verifiëren",
        "cancel": "Annuleren",
        "connect": "Verbinden",
        "connected": "\"{{connectionName}}\" verbonden",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Verbindingen",
        "continue": "Doorgaan",
        "copy": "Beheer de accountbrede verbindingen die voor dit account zijn gemaakt.",
        "create_connection": "Verbinding maken",
        "create_connection_failed": "Kan \"{{connectionName}}\" niet maken.",
        "created": "\"{{connectionName}}\" gemaakt",
        "created_by": "Gemaakt door {{ownerName}} op {{dateCreated}}.",
        "created_on": "Gemaakt op {{dateCreated}}.",
        "delete": "Verwijderen",
        "delete_connection": "{{connectionName}} verwijderen",
        "delete_connection_copy": "Weet u zeker dat u \"{{connectionName}}\" wilt verwijderen? Alles wat deze verbinding gebruikt, heeft een nieuwe verbinding nodig om te kunnen blijven werken.",
        "delete_connection_failed": "Kan \"{{connectionName}}\" niet verwijderen.",
        "deleted_connection_successfully": "\"{{connectionName}}\" is verwijderd.",
        "disable": "Uitschakelen",
        "disconnect": "Verbreken",
        "disconnect_connection": "Verbinding met \"{{connectionName}}\" verbreken",
        "disconnect_connection_copy": "Weet u zeker dat u de verbinding met \"{{connectionName}}\" wilt verbreken? Alles wat deze verbinding gebruikt, werkt niet meer totdat er opnieuw verbinding wordt gemaakt.",
        "disconnect_connection_failed": "Kan verbinding met \"{{connectionName}}\" niet verbreken.",
        "disconnected_connection_successfully": "Verbinding met \"{{connectionName}}\" is verbroken.",
        "edit": "Bewerken",
        "edit_connection": "Verbinding bewerken",
        "new_connection": "Nieuwe verbinding",
        "no_connections": "Geen verbindingen",
        "reconnecting_connection": "Opnieuw verbinden",
        "required_connection_options": "Vereiste verbindingsopties",
        "save": "Opslaan",
        "update_connection_failed": "Kan \"{{connectionName}}\" niet bijwerken.",
        "updated_connection_successfully": "\"{{connectionName}}\" is bijgewerkt.",
        "validate_connection_failed": "Kan verbinding niet valideren.",
        "validating_connection": "Verbinding valideren"
      },
      "connection_manager_actions": {
        "connect": "Verbinden",
        "delete": "Verwijderen",
        "details": "Details",
        "disconnect": "Verbreken",
        "manage_access": "Toegang beheren",
        "rename": "Naam wijzigen",
        "selected": "geselecteerd",
        "share": "Delen"
      },
      "connection_manager_delete": {
        "delete": "Verwijderen",
        "description1": "Weet u zeker dat u \"{{connectionName}}\" wilt verwijderen? ",
        "description2": "U kunt deze verbinding niet meer gebruiken met integraties.",
        "title": "Verbinding verwijderen"
      },
      "connection_manager_disconnect": {
        "description": "Hierdoor worden de verificatiegegevens en alle connectorinstellingen verwijderd. Alles wat deze verbinding gebruikt, zal niet meer werken totdat er opnieuw verbinding wordt gemaakt.",
        "disconnect": "Verbreken",
        "title": "Weet u zeker dat u de verbinding met \"{{connectionName}}\" wilt verbreken?"
      },
      "connection_manager_drawer": {
        "alert_info": "Standaard bent u de enige die gebruik kan maken van de verbinding die u maakt.",
        "catalog": "Door catalogus bladeren",
        "description": "Hier kunt u een verbinding tot stand brengen met beschikbare diensten. U kunt meerdere verbindingen met dezelfde service maken.",
        "footer_description": "Ziet u niet wat u nodig heeft? Ontdek de ShareFile-catalogus om meer manieren te vinden om uw account uit te breiden!",
        "footer_title": "Op zoek naar meer?",
        "services_header": "Beschikbare services"
      },
      "connection_manager_page": {
        "description": "De functionaliteit van ShareFile kan worden uitgebreid met externe services. Op deze pagina kunt u uw accountverbindingen met deze services beheren.",
        "title": "Verbindingen"
      },
      "connection_manager_rename": {
        "cancel": "Annuleren",
        "error": "Voer een geldige naam in voor de verbinding",
        "form_title": "Naam van de verbinding wijzigen",
        "label": "Verbindingsnaam",
        "placeholder": "Voer een nieuwe naam in voor de verbinding",
        "save": "Opslaan",
        "updating_connection": "Verbinding bijwerken"
      },
      "connection_manager_tab": {
        "my_connections": "Mijn verbindingen",
        "shared_with_me": "Gedeeld met mij"
      },
      "connection_manager_table": {
        "actions_message": "Selecteer items om acties te zien",
        "created_by": "Gemaakt door",
        "date_added": "Datum toegevoegd",
        "name": "Naam",
        "no_connections_header": "Welkom bij uw verbindingen",
        "no_connections_message": "U hebt geen verbindingen met externe services gemaakt. U hebt een verbinding nodig om gebruik te kunnen maken van integraties met een service.",
        "no_shared_connections": "Geen gedeelde verbindingen",
        "selected": "geselecteerd",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Gemaakt door",
        "created_on": "Gemaakt op"
      },
      "connections_permission": {
        "delete": "Verbinding verwijderen",
        "entity": "Verbinding",
        "modify": "Verbinding wijzigen",
        "preset": {
          "member": "Lid",
          "owner": "Eigenaar"
        },
        "read": "Verbinding lezen",
        "share": "Verbinding delen"
      },
      "connections_share": {
        "remove_success": "Verwijderd.",
        "share_success": "Geselecteerde verbindingen zijn gedeeld."
      },
      "connections_status": {
        "connected": "Verbonden",
        "disconnected": "Verbinding verbroken"
      },
      "contact_records": {
        "empty_records": "Geen overeenkomende records",
        "error_message": "Fout bij het zoeken naar contactpersonen. Neem contact op met je beheerder om de verbinding met derden te herstellen.",
        "loading_contacts": "Contactpersonen laden...",
        "search_prospects": "Potentiële klanten zoeken"
      },
      "details_modal": {
        "developer": "Ontwikkelaar",
        "last_updated": "Laatst bijgewerkt",
        "support": "Ondersteuning",
        "version": "Versie"
      },
      "errors": {
        "pilet_error": "Pilet API niet beschikbaar. Navigatie-items worden niet geregistreerd"
      },
      "import": {
        "import_button": "Importeren",
        "import_prospect": "Potentiële klant importeren",
        "search": "Zoeken",
        "search_with_dots": "Zoeken...",
        "select_connection": "Verbinding selecteren:",
        "unknown_option": "Onbekende optie"
      },
      "notifications": {
        "install": "Integratie geïnstalleerd",
        "uninstall": "Integratie verwijderd",
        "warning_message": "Weet u zeker dat u deze integratie volledig wilt verwijderen? Alle geïntegreerde gegevens uit uw ShareFile-account worden hiermee verwijderd. Dit proces kan niet worden teruggedraaid."
      },
      "table_labels": {
        "email": "E-mail",
        "name": "Naam",
        "phone": "Telefoon"
      },
      "third_party_app": {
        "navigation_description": "Integraties worden verplaatst naar de ShareFile-catalogus. Dit stelt ons in staat om ons aanbod uit te breiden en u een betere ervaring te bieden. Bekijk vanaf nu de catalogus om ons toenemende integratieaanbod te zien.",
        "navigation_message": "Integraties van derden worden verplaatst",
        "open_catalog": "Catalogus openen"
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "actions": {
        "loading": "Carregando...",
        "send_agreement": "Enviar o contrato do cliente"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "Adicionar",
        "details": "Detalhes",
        "uninstall": "Desinstalar"
      },
      "connected_entity_search": {
        "no_results": "Nenhum resultado encontrado para sua pesquisa",
        "searching": "Pesquisando"
      },
      "connection_manager": {
        "authenticate": "Autenticar",
        "cancel": "Cancelar",
        "connect": "Conectar",
        "connected": "\"{{connectionName}}\" conectada",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Conexões",
        "continue": "Continuar",
        "copy": "Gerencie as conexões em todas as contas que foram criadas para esta conta.",
        "create_connection": "Criar conexão",
        "create_connection_failed": "Falha ao criar \"{{connectionName}}\".",
        "created": "\"{{connectionName}}\" criada",
        "created_by": "Criada por {{ownerName}} em {{dateCreated}}.",
        "created_on": "Criada em {{dateCreated}}.",
        "delete": "Excluir",
        "delete_connection": "Excluir {{connectionName}}",
        "delete_connection_copy": "Tem certeza de que deseja excluir \"{{connectionName}}\"? Tudo que usar essa conexão exigirá uma nova conexão para continuar funcionando.",
        "delete_connection_failed": "Falha ao excluir \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" excluída com êxito.",
        "disable": "Desativar",
        "disconnect": "Desconectar",
        "disconnect_connection": "Desconectar \"{{connectionName}}\"",
        "disconnect_connection_copy": "Tem certeza de que deseja desconectar \"{{connectionName}}\"? Tudo que usar essa conexão deixará de funcionar até que seja reconectado.",
        "disconnect_connection_failed": "Falha ao desconectar \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" desconectada com êxito.",
        "edit": "Editar",
        "edit_connection": "Editar conexão",
        "new_connection": "Nova conexão",
        "no_connections": "Sem conexões",
        "reconnecting_connection": "Reconectando a conexão",
        "required_connection_options": "Opções de conexão necessárias",
        "save": "Salvar",
        "update_connection_failed": "Falha ao atualizar \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" atualizada com êxito.",
        "validate_connection_failed": "Falha ao validar a conexão.",
        "validating_connection": "Validando a conexão"
      },
      "connection_manager_actions": {
        "connect": "Conectar",
        "delete": "Excluir",
        "details": "Detalhes",
        "disconnect": "Desconectar",
        "manage_access": "Gerenciar acesso",
        "rename": "Renomear",
        "selected": "selecionado(s)",
        "share": "Compartilhar"
      },
      "connection_manager_delete": {
        "delete": "Excluir",
        "description1": "Tem certeza de que deseja excluir \"{{connectionName}}\"? ",
        "description2": "Você não poderá mais usar esta conexão com nenhuma integração.",
        "title": "Excluir conexão"
      },
      "connection_manager_disconnect": {
        "description": "Isso excluirá os detalhes de autenticação e todas as configurações do conector. Qualquer coisa que usar esta conexão deixará de funcionar até que seja reconectada.",
        "disconnect": "Desconectar",
        "title": "Tem certeza de que deseja desconectar a conexão \"{{connectionName}}\"?"
      },
      "connection_manager_drawer": {
        "alert_info": "Por padrão, você é o único que pode usar a conexão criada.",
        "catalog": "Navegar no catálogo",
        "description": "Aqui você pode criar uma conexão aos serviços disponíveis. Você pode criar várias conexões ao mesmo serviço.",
        "footer_description": "Não está vendo o que você precisa? Explore o catálogo do ShareFile para encontrar mais maneiras de expandir sua conta.",
        "footer_title": "Procurando mais?",
        "services_header": "Serviços disponíveis"
      },
      "connection_manager_page": {
        "description": "A funcionalidade do ShareFile pode ser expandida com serviços externos. Esta página permite gerenciar as conexões de sua conta com esses serviços.",
        "title": "Conexões"
      },
      "connection_manager_rename": {
        "cancel": "Cancelar",
        "error": "Insira um nome de conexão válido",
        "form_title": "Renomear conexão",
        "label": "Nome da conexão",
        "placeholder": "Insira um novo nome para a conexão",
        "save": "Salvar",
        "updating_connection": "Atualizando conexão"
      },
      "connection_manager_tab": {
        "my_connections": "Minhas conexões",
        "shared_with_me": "Compartilhado comigo"
      },
      "connection_manager_table": {
        "actions_message": "Selecione itens para ver as ações",
        "created_by": "Criado por",
        "date_added": "Data da adição",
        "name": "Nome",
        "no_connections_header": "Bem-vindo(a) às suas conexões",
        "no_connections_message": "Você não criou nenhuma conexão aos serviços externos. Você precisará de uma conexão para usar as integrações a um serviço.",
        "no_shared_connections": "Nenhuma conexão compartilhada",
        "selected": "selecionado(s)",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Criado por",
        "created_on": "Criado em"
      },
      "connections_permission": {
        "delete": "Excluir conexão",
        "entity": "Conexão",
        "modify": "Modificar conexão",
        "preset": {
          "member": "Membro",
          "owner": "Proprietário"
        },
        "read": "Ler conexão",
        "share": "Compartilhar conexão"
      },
      "connections_share": {
        "remove_success": "Removido com êxito.",
        "share_success": "Conexões selecionadas compartilhadas com êxito."
      },
      "connections_status": {
        "connected": "Conectado",
        "disconnected": "Desconectado"
      },
      "contact_records": {
        "empty_records": "Sem registros correspondentes",
        "error_message": "Erro ao pesquisar contatos. Entre em contato com seu administrador para restabelecer a conexão de terceiros.",
        "loading_contacts": "Carregando contatos...",
        "search_prospects": "Buscar clientes em potencial"
      },
      "details_modal": {
        "developer": "Desenvolvedor",
        "last_updated": "Última atualização",
        "support": "Suporte",
        "version": "Versão"
      },
      "errors": {
        "pilet_error": "Pilet API não disponível, os itens de navegação não estão sendo registrados"
      },
      "import": {
        "import_button": "Importar",
        "import_prospect": "Importar cliente em potencial",
        "search": "Busca",
        "search_with_dots": "Pesquisar...",
        "select_connection": "Selecionar conexão:",
        "unknown_option": "Opção desconhecida"
      },
      "notifications": {
        "install": "Integração instalada",
        "uninstall": "Integração desinstalada",
        "warning_message": "Tem certeza de que deseja desinstalar completamente essa integração? Isso removerá todos os dados integrados da sua conta do ShareFile. Esse processo não pode ser revertido."
      },
      "table_labels": {
        "email": "Email",
        "name": "Nome",
        "phone": "Telefone"
      },
      "third_party_app": {
        "navigation_description": "As integrações estão migrando para o Catálogo do ShareFile. Isso nos permitirá expandir nossas ofertas e proporcionar uma experiência melhor para você. Para ver nossas crescentes ofertas de integrações, confira o catálogo a partir de agora.",
        "navigation_message": "As integrações de terceiros estão sendo migradas",
        "open_catalog": "Abrir catálogo"
      }
    }
  },
  {
    "key": "ru",
    "content": {
      "actions": {
        "loading": "Loading...",
        "send_agreement": "Отправить клиентское соглашение"
      },
      "beta": "(Бета-версия)",
      "button_labels": {
        "add": "Добавить",
        "details": "Сведения",
        "uninstall": "Удалить"
      },
      "connected_entity_search": {
        "no_results": "No results found",
        "searching": "Searching"
      },
      "connection_manager": {
        "authenticate": "Authenticate",
        "cancel": "Cancel",
        "connect": "Connect",
        "connected": "Connected \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connections",
        "continue": "Continue",
        "copy": "Manage the account-wide connections that have been created for this account.",
        "create_connection": "Create connection",
        "create_connection_failed": "Failed to update \"{{connectionName}}\".",
        "created": "Created \"{{connectionName}}\"",
        "created_by": "Created by {{ownerName}} on {{dateCreated}}.",
        "created_on": "Created on {{dateCreated}}.",
        "delete": "Delete",
        "delete_connection": "Delete {{connectionName}}",
        "delete_connection_copy": "Are you sure you want to delete \"{{connectionName}}\"? Anything using this connection will require a new connection to continue working.",
        "delete_connection_failed": "Failed to delete \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" deleted successfully.",
        "disable": "Disable",
        "disconnect": "Disconnect",
        "disconnect_connection": "Disconnect \"{{connectionName}}\"",
        "disconnect_connection_copy": "Are you sure you want to disconnect \"{{connectionName}}\"? Anything using this connection will stop working until it is reconnected.",
        "disconnect_connection_failed": "Failed to disconnect \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" disconnected successfully.",
        "edit": "Edit",
        "edit_connection": "Edit Connection",
        "new_connection": "New connection",
        "no_connections": "No connections",
        "reconnecting_connection": "Reconnecting Connection",
        "required_connection_options": "Required connection options",
        "save": "Save",
        "update_connection_failed": "Failed to update \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" updated successfully.",
        "validate_connection_failed": "Failed to validate connection.",
        "validating_connection": "Validating Connection"
      },
      "connection_manager_actions": {
        "connect": "Connect",
        "delete": "Delete",
        "details": "Details",
        "disconnect": "Disconnect",
        "manage_access": "Manage Access",
        "rename": "Rename",
        "selected": "selected",
        "share": "Share"
      },
      "connection_manager_delete": {
        "delete": "Delete",
        "description1": "Are you sure you want to delete \"{{connectionName}}\"? ",
        "description2": "You will no longer be able to use this connection with any integrations.",
        "title": "Delete connection"
      },
      "connection_manager_disconnect": {
        "description": "This will delete authentication details and all connector settings. Anything using this connection will stop working until it is reconnected.",
        "disconnect": "Disconnect",
        "title": "Are you sure you want to disconnect \"{{connectionName}}\" connection?"
      },
      "connection_manager_drawer": {
        "alert_info": "By default, you are the only one who can use the connection you create.",
        "catalog": "Browse catalog",
        "description": "Here you can create a connection to available services. You can create multiple connections to the same service.",
        "footer_description": "Not seeing what you need?Explore the ShareFile catalog to find more ways to expand your account!",
        "footer_title": "Looking for more?",
        "services_header": "Available Services"
      },
      "connection_manager_page": {
        "description": "ShareFile's functionality can be expanded with external services.This page allows you to manage your account connections with these services.",
        "title": "Connections"
      },
      "connection_manager_rename": {
        "cancel": "Cancel",
        "error": "Please enter a valid connection name",
        "form_title": "Rename Connection",
        "label": "Connection Name",
        "placeholder": "Enter a new name for the connection",
        "save": "Save",
        "updating_connection": "Updating Connection"
      },
      "connection_manager_tab": {
        "my_connections": "My Connections",
        "shared_with_me": "Shared with me"
      },
      "connection_manager_table": {
        "actions_message": "Select items to see actions",
        "created_by": "Created By",
        "date_added": "Date Added",
        "name": "Name",
        "no_connections_header": "Welcome to your connections",
        "no_connections_message": "YOu haven't created any connections to external services. You'll need a connection to make use of integrations with a service.",
        "no_shared_connections": "No shared connections",
        "selected": "selected",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Created by",
        "created_on": "Created on"
      },
      "connections_permission": {
        "delete": "Delete connection",
        "entity": "Connection",
        "modify": "Modify connection",
        "preset": {
          "member": "Member",
          "owner": "Owner"
        },
        "read": "Read Connection",
        "share": "Share connection"
      },
      "connections_share": {
        "remove_success": "Removed successfully.",
        "share_success": "Selected connections shared successfully."
      },
      "connections_status": {
        "connected": "Connected",
        "disconnected": "Disconnected"
      },
      "contact_records": {
        "empty_records": "Нет совпадающих записей",
        "error_message": "Error searching contacts. Please contact your admin to re-establish the third-party connection.",
        "loading_contacts": "Загрузка контактов...",
        "search_prospects": "Искать потенциальных клиентов"
      },
      "details_modal": {
        "developer": "Разработчик",
        "last_updated": "Последнее обновление",
        "support": "Поддержка",
        "version": "Версия"
      },
      "errors": {
        "pilet_error": "Pilet API недоступен, элементы навигации не регистрируются"
      },
      "import": {
        "import_button": "Import",
        "import_prospect": "Import Prospect",
        "search": "Search",
        "search_with_dots": "Search...",
        "select_connection": "Select Connection:",
        "unknown_option": "Unknown option"
      },
      "notifications": {
        "install": "Интеграция установлена",
        "uninstall": "Интеграция удалена",
        "warning_message": "Действительно полностью удалить эту интеграцию? Это удалит все интегрированные данные из вашей учетной записи ShareFile. Это действие необратимо."
      },
      "table_labels": {
        "email": "Адрес электронной почты",
        "name": "Имя",
        "phone": "Телефон"
      },
      "third_party_app": {
        "navigation_description": "Integrations are moving to the ShareFile Catalog. This will allow us to expand our offerings and provide a better experience for you. To see our growing integrations offerings, check out the catalog from now on.",
        "navigation_message": "Third party integrations are moving",
        "open_catalog": "Open catalog"
      }
    }
  },
  {
    "key": "zh-CN",
    "content": {
      "actions": {
        "loading": "Loading...",
        "send_agreement": "发送客户协议"
      },
      "beta": "(Beta)",
      "button_labels": {
        "add": "添加",
        "details": "详细信息",
        "uninstall": "卸载"
      },
      "connected_entity_search": {
        "no_results": "No results found",
        "searching": "Searching"
      },
      "connection_manager": {
        "authenticate": "Authenticate",
        "cancel": "Cancel",
        "connect": "Connect",
        "connected": "Connected \"{{connectionName}}\"",
        "connection_name_description": "Give a name to your new \"{{integrationName}}\" connection. This will help you identify it later.",
        "connections": "Connections",
        "continue": "Continue",
        "copy": "Manage the account-wide connections that have been created for this account.",
        "create_connection": "Create connection",
        "create_connection_failed": "Failed to update \"{{connectionName}}\".",
        "created": "Created \"{{connectionName}}\"",
        "created_by": "Created by {{ownerName}} on {{dateCreated}}.",
        "created_on": "Created on {{dateCreated}}.",
        "delete": "Delete",
        "delete_connection": "Delete {{connectionName}}",
        "delete_connection_copy": "Are you sure you want to delete \"{{connectionName}}\"? Anything using this connection will require a new connection to continue working.",
        "delete_connection_failed": "Failed to delete \"{{connectionName}}\".",
        "deleted_connection_successfully": "\"{{connectionName}}\" deleted successfully.",
        "disable": "Disable",
        "disconnect": "Disconnect",
        "disconnect_connection": "Disconnect \"{{connectionName}}\"",
        "disconnect_connection_copy": "Are you sure you want to disconnect \"{{connectionName}}\"? Anything using this connection will stop working until it is reconnected.",
        "disconnect_connection_failed": "Failed to disconnect \"{{connectionName}}\".",
        "disconnected_connection_successfully": "\"{{connectionName}}\" disconnected successfully.",
        "edit": "Edit",
        "edit_connection": "Edit Connection",
        "new_connection": "New connection",
        "no_connections": "No connections",
        "reconnecting_connection": "Reconnecting Connection",
        "required_connection_options": "Required connection options",
        "save": "Save",
        "update_connection_failed": "Failed to update \"{{connectionName}}\".",
        "updated_connection_successfully": "\"{{connectionName}}\" updated successfully.",
        "validate_connection_failed": "Failed to validate connection.",
        "validating_connection": "Validating Connection"
      },
      "connection_manager_actions": {
        "connect": "Connect",
        "delete": "Delete",
        "details": "Details",
        "disconnect": "Disconnect",
        "manage_access": "Manage Access",
        "rename": "Rename",
        "selected": "selected",
        "share": "Share"
      },
      "connection_manager_delete": {
        "delete": "Delete",
        "description1": "Are you sure you want to delete \"{{connectionName}}\"? ",
        "description2": "You will no longer be able to use this connection with any integrations.",
        "title": "Delete connection"
      },
      "connection_manager_disconnect": {
        "description": "This will delete authentication details and all connector settings. Anything using this connection will stop working until it is reconnected.",
        "disconnect": "Disconnect",
        "title": "Are you sure you want to disconnect \"{{connectionName}}\" connection?"
      },
      "connection_manager_drawer": {
        "alert_info": "By default, you are the only one who can use the connection you create.",
        "catalog": "Browse catalog",
        "description": "Here you can create a connection to available services. You can create multiple connections to the same service.",
        "footer_description": "Not seeing what you need?Explore the ShareFile catalog to find more ways to expand your account!",
        "footer_title": "Looking for more?",
        "services_header": "Available Services"
      },
      "connection_manager_page": {
        "description": "ShareFile's functionality can be expanded with external services.This page allows you to manage your account connections with these services.",
        "title": "Connections"
      },
      "connection_manager_rename": {
        "cancel": "Cancel",
        "error": "Please enter a valid connection name",
        "form_title": "Rename Connection",
        "label": "Connection Name",
        "placeholder": "Enter a new name for the connection",
        "save": "Save",
        "updating_connection": "Updating Connection"
      },
      "connection_manager_tab": {
        "my_connections": "My Connections",
        "shared_with_me": "Shared with me"
      },
      "connection_manager_table": {
        "actions_message": "Select items to see actions",
        "created_by": "Created By",
        "date_added": "Date Added",
        "name": "Name",
        "no_connections_header": "Welcome to your connections",
        "no_connections_message": "YOu haven't created any connections to external services. You'll need a connection to make use of integrations with a service.",
        "no_shared_connections": "No shared connections",
        "selected": "selected",
        "status": "Status"
      },
      "connections_details": {
        "created_by": "Created by",
        "created_on": "Created on"
      },
      "connections_permission": {
        "delete": "Delete connection",
        "entity": "Connection",
        "modify": "Modify connection",
        "preset": {
          "member": "Member",
          "owner": "Owner"
        },
        "read": "Read Connection",
        "share": "Share connection"
      },
      "connections_share": {
        "remove_success": "Removed successfully.",
        "share_success": "Selected connections shared successfully."
      },
      "connections_status": {
        "connected": "Connected",
        "disconnected": "Disconnected"
      },
      "contact_records": {
        "empty_records": "没有匹配的记录",
        "error_message": "Error searching contacts. Please contact your admin to re-establish the third-party connection.",
        "loading_contacts": "正在加载联系人...",
        "search_prospects": "搜索潜在客户"
      },
      "details_modal": {
        "developer": "开发者",
        "last_updated": "上次更新时间",
        "support": "支持",
        "version": "版本"
      },
      "errors": {
        "pilet_error": "Pilet API 不可用，未注册导航项目"
      },
      "import": {
        "import_button": "Import",
        "import_prospect": "Import Prospect",
        "search": "Search",
        "search_with_dots": "Search...",
        "select_connection": "Select Connection:",
        "unknown_option": "Unknown option"
      },
      "notifications": {
        "install": "已安装集成",
        "uninstall": "已卸载集成",
        "warning_message": "是否确实要完全卸载此集成? 这将从您的 ShareFile 帐户中删除所有集成的数据。此过程无法撤消。"
      },
      "table_labels": {
        "email": "电子邮件",
        "name": "名称",
        "phone": "电话"
      },
      "third_party_app": {
        "navigation_description": "Integrations are moving to the ShareFile Catalog. This will allow us to expand our offerings and provide a better experience for you. To see our growing integrations offerings, check out the catalog from now on.",
        "navigation_message": "Third party integrations are moving",
        "open_catalog": "Open catalog"
      }
    }
  }
];