import React from 'react';
import { BaseComponentProps, PiletApi } from '@sharefiledev/sharefile-appshell';

const PiralContext = React.createContext<PiletApi | undefined>(undefined);

export function usePiralContext() {
	return React.useContext(PiralContext);
}

export function withPiralContext<T extends BaseComponentProps>(
	Component: React.ComponentType<T>
) {
	return (props: T) => (
		<PiralContext.Provider value={props.piral}>
			<Component {...props} />
		</PiralContext.Provider>
	);
}
