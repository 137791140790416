/* eslint-disable @typescript-eslint/no-shadow */

import React, { useEffect } from 'react';
import { WorkflowStatus } from '@citrite/sf-api';
import { withModals, WithModalsProps } from '@sharefiledev/flow-web';
import { useQuery } from '@tanstack/react-query';
import { Connection, ContactRecord, globalIdForRecord } from '../../api/models';
import { getIntegrationRecords } from '../../api/records';
import {
	fetchTriggersForContacts,
	storeTriggersForContacts,
	WorkflowTrigger,
} from '../../api/workflow-triggers';
import { pendoSendTrackEvent } from '../../helpers/pendo';
import { useDebounce } from '../../hooks/debounce';
import { useRequiredAccountId } from '../../state/user-state';
import { t } from '../../translate';
import { ContactRecordsTable } from '../ContactRecordsTable';
import {
	StyledPageContainer,
	StyledPageTitle,
	StyledTitleItem,
} from './ContactRecordsBrowser.styled';

export interface Props extends WithModalsProps {
	integrationId: string;
	connectionRID: string;
	title: string;
}

const _ContactRecordsBrowser = (props: Props) => {
	const { integrationId, connectionRID, title } = props;
	const accountId = useRequiredAccountId();
	const [selectedContacts, setSelectedContacts] = React.useState<ContactRecord[]>([]);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);

	const contactsQuery = useQuery({
		queryKey: ['contacts', { integrationId, accountId, searchTerm: debouncedSearchTerm }],
		onError: () => [],
		queryFn: async () => {
			pendoSendTrackEvent('Integrations - Fetch contacts', {
				accountId,
				integrationId,
				searchTerm: debouncedSearchTerm,
				name: title,
			});

			return getIntegrationRecords({
				connectionRID,
				searchTerm: debouncedSearchTerm,
			});
		},
	});

	const contacts = contactsQuery.data ?? [];

	const workflowTriggersQuery = useQuery({
		queryKey: ['workflow-triggers', { contacts }],
		queryFn: () => fetchTriggersForContacts(contacts, integrationId),
		onError: () => [],
		cacheTime: 1000,
	});

	const triggerIdsForSelectedContacts = React.useMemo(() => {
		const triggers = workflowTriggersQuery.data;
		if (!triggers) {
			return [];
		}
		return selectedContacts
			.map(c => triggers.find(t => t.ProspectId === globalIdForRecord(c, integrationId)))
			.filter(t => !!t)
			.map(t => t!.TriggerId);
	}, [workflowTriggersQuery, selectedContacts]);

	const refetchWorkflowTriggers = () => workflowTriggersQuery.refetch();

	useEffect(() => {
		if (!contactsQuery.data) {
			return;
		}
		refetchWorkflowTriggers();
	}, [contactsQuery]);

	const workflowStatus = (contact: ContactRecord) => {
		const contactId = globalIdForRecord(contact, integrationId);
		const triggers = workflowTriggersQuery.data;
		if (!triggers) {
			return WorkflowStatus.Unknown;
		}
		const hasTrigger = triggers.some(t => t.ProspectId === contactId);

		if (hasTrigger) {
			return WorkflowStatus.InProgress;
		}
		return WorkflowStatus.Unknown;
	};

	const onWorkflowsTriggered = (
		triggeredContacts: ContactRecord[],
		triggers: WorkflowTrigger[]
	) => {
		pendoSendTrackEvent('Integrations - Trigger Accelerated Agreement', {
			accountId,
			integrationId,
			triggeredContacts: triggeredContacts.map(c => c.externalRecordId),
			triggers: triggers.map(t => t.TriggerId),
		});

		storeTriggersForContacts(triggeredContacts, triggers, integrationId).then(() =>
			refetchWorkflowTriggers()
		);
	};

	return (
		<StyledPageContainer>
			<StyledPageTitle>
				<StyledTitleItem>
					{/* TODO: localize titles */}
					{`${title} ${t('integrations:beta')}`}
				</StyledTitleItem>
			</StyledPageTitle>
			<ContactRecordsTable
				integrationId={integrationId}
				contacts={contacts ?? []}
				selectedContacts={selectedContacts}
				setSelectedContacts={setSelectedContacts}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				isLoading={contactsQuery.isLoading}
				triggerIdsForSelectedContacts={triggerIdsForSelectedContacts}
				workflowStatus={workflowStatus}
				onWorkflowsTriggered={onWorkflowsTriggered}
			/>
		</StyledPageContainer>
	);
};

export const ContactRecordsBrowser = withModals(_ContactRecordsBrowser);
