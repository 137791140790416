import React from 'react';
import {
	StartProspectFlowButton,
	StyledSpanForFlowButton,
} from './StartProspectFlowAction.styled';

/**
 * Renders a mock "Send Client Agreement" button, which is usually
 * provided by a Piral extension via the Domino workflows pilet.
 *
 * This mock component is only rendered in Storybooks, where the Piral API
 * is not available.
 */
export function MockProspectWorkflowExtension(props: {
	onAction: () => void;
	actionName: string;
	inputs: any[];
}) {
	return (
		<StyledSpanForFlowButton
			title={props.inputs.length === 0 ? 'Select a client to send an agreement' : ''}
		>
			<StartProspectFlowButton
				onClick={props.onAction}
				disabled={props.inputs.length === 0}
				data-analytics-id="prospectListSendClientAgreementButton"
			>
				{props.actionName}
			</StartProspectFlowButton>
		</StyledSpanForFlowButton>
	);
}
