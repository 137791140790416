/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import type { PiletApi } from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'integrations-pilet';

export const integrationsExtensionSlots = {
	catalog: `${prefix}:${namespace}:${owner}:catalog` as const,
	createConnection: `${prefix}:${namespace}:${owner}:create-connection` as const,
	connectButton: `${prefix}:${namespace}:${owner}:connect-button` as const,
	connectionManager: `${prefix}:${namespace}:${owner}:connection-manager` as const,
	personalConnectionManager:
		`${prefix}:${namespace}:${owner}:personal-connection-manager` as const,
	searchContacts: `${prefix}:${namespace}:${owner}:search-contacts` as const,
	connectedEntityAutoComplete:
		`${prefix}:${namespace}:${owner}:connected-entity-auto-complete` as const,
};

export const legacyExtensionSlots = {
	catalog:
		`${prefix}:${namespace}:sharefile-appshell:apps:thirdparty-integrations:catalog` as const,
	createConnection:
		`${prefix}:sfextslot:sharefile-appshell:thirdparty-integrations:apps:workflows-pilet:createNewEntity` as const,
	connectButton:
		`${prefix}:sfextslot:sharefile-appshell:apps:thirdparty-integrations:apps:connect-button` as const,
	connectionManager:
		`${prefix}:sfextslot:sharefile-appshell:settings:admin-settings:connection-manager` as const,
	searchContacts:
		`${prefix}:sfextslot:sharefile-appshell:apps:thirdparty-integrations:search-contacts` as const,
};

export interface EmptyParams {}

type SetConnectionValue = {
	Id: string;
	Name: string;
	ConnectorName: string;
	ConnectorVersion?: string;
	ConnectorId: string | number;
};

export interface CreateConnectionExtensionParams {
	entityType: string;
	setEntityPickerValue: (value: SetConnectionValue) => void;
	typeArgs?: {
		connectorName?: string;
		integrationAPIVersion?: string;
		autoName?: boolean;
	};
	disabled: boolean;
}

export interface SearchContactsExtensionParams {
	onSelect: (value: unknown) => void;
}

export interface ConnectedEntityAutocompleteExtensionParams {
	connectedEntityRID: string;
	placeholder?: string;
	size?: string;
	defaultValue?: any;
	style?: any;
	onSelect: (value: unknown) => void;
	onBlur?: (value: unknown) => void;
	onError?: (value: unknown) => void;
	onClear?: () => void;
}

export type ExtensionImplementationProps<TParams> = TParams & { piral: PiletApi };

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		// actively using proper pattern
		[integrationsExtensionSlots.connectedEntityAutoComplete]: ConnectedEntityAutocompleteExtensionParams;

		// TODO: migrate to these
		[integrationsExtensionSlots.catalog]: EmptyParams;
		[integrationsExtensionSlots.createConnection]: CreateConnectionExtensionParams;
		[integrationsExtensionSlots.connectButton]: EmptyParams;
		[integrationsExtensionSlots.connectionManager]: EmptyParams;
		[integrationsExtensionSlots.personalConnectionManager]: EmptyParams;
		[integrationsExtensionSlots.searchContacts]: SearchContactsExtensionParams;
		// TODO: migrate away and delete these
		[legacyExtensionSlots.catalog]: EmptyParams;
		[legacyExtensionSlots.createConnection]: CreateConnectionExtensionParams;
		[legacyExtensionSlots.connectButton]: EmptyParams;
		[legacyExtensionSlots.connectionManager]: EmptyParams;
		[legacyExtensionSlots.searchContacts]: SearchContactsExtensionParams;
	}
}
