import React from 'react';
import { notification, NotificationArgsProps } from 'antd';
import { NotificationContext } from '../../context';

export interface TriggerNotificationProps
	extends Omit<NotificationArgsProps, 'duration' | 'closeIcon'> {
	type?: 'success' | 'info' | 'warning' | 'error';
}

interface Props {
	children?: React.ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
	const [api, contextHolder] = notification.useNotification();

	const triggerNotification = (props: TriggerNotificationProps): void => {
		const notificationProps = { ...props, duration: 4.5, closeIcon: false };
		props.type ? api[props.type](notificationProps) : api.open(notificationProps);
	};

	return (
		<NotificationContext.Provider value={{ triggerNotification }}>
			{children}
			{contextHolder}
		</NotificationContext.Provider>
	);
};

export default NotificationProvider;
