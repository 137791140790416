import React from 'react';
import styled from '@emotion/styled';
import { Button, fromThemeColors, PageTitle, ThemeColor } from '@sharefiledev/flow-web';

export const StyledPageContainer = styled.div({
	padding: '20px',
});

export const StyledLoadMore = styled(Button.Wrapper)`
	width: 100%;
	display: flex;
	align-items: center;
	height: 32px;
	border: 1px solid ${fromThemeColors(ThemeColor.border)};
`;

export const StyledLoadMoreContents = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const StyledSpinner = styled.div`
	padding-right: 8px;
`;

export const StyledTitleItem = styled.span`
	display: inline-block;
	vertical-align: middle;
	margin: 10px 0px;
`;

export const StyledPageTitle = styled(PageTitle)`
	display: flex;
	justify-content: space-between;
`;
